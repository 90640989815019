import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './css/main.css';
import Menu from './Menu';
import TopBar from './TopBar';
import axios from 'axios';
import Chart, { ChartTypeRegistry, ChartConfiguration } from 'chart.js/auto';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers';
import { fetchBusinessStatus } from './functions/functions';
import { exportToExcelStatistics } from './functions/excel';
import config from './config/config';

interface Conversation {
  category: string;
  last_message_date: string,
  user_name: string;
  id: string;
  threadId: string;
  business_id: string;
  summary: string;
  user_phone: string;
  renata_sleep: boolean;
}

interface Interaction {
  _id: string;
  interaction_phone: string;
  interaction_date: string;
  thread_id: string;
  inputToken: number;
  outputToken: number;
  userMessages: number;
}

interface Message {
  _id: string;
  message_phone: string;
  message_date: string;
  thread_id: string;
}

interface Statistics {
  _id: string;
  business_id: string;
  interactions: Interaction[];
  messages: Message[];
}

function Statistics() {
  const gojimx_token = localStorage.getItem('gojimx_token');
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');

  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState('Today');
  const [conversationsCount, setConversationsCount] = useState(0);
  const [interactionsCount, setInteractionsCount] = useState(0);
  const [totalMessagesCount, setTotalMessagesCount] = useState(0);
  const [statistics, setStatistics] = useState<Statistics | null>(null);
  const [conversations, setConversations] = useState<Conversation[] | null>(null);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [chartCreated, setChartCreated] = useState(false);
  const [categoryCounts, setCategoryCounts] = useState<{ [key: string]: number }>({});
  const [filteredInteractions, setFilteredInteractions] = useState<Interaction[]>([]);
  const [filteredConversations, setFilteredConversations] = useState<Conversation[]>([]);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [formatLabelSegment, setFormatLabelSegment] = useState('Hoy');
  const [inputToken, setInputToken] = useState(0);
  const [outputToken, setOutputToken] = useState(0);

  const handleOpenCalendarModal = () => setShowCalendarModal(true);
  const handleCloseCalendarModal = () => setShowCalendarModal(false);

  const handleStartDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    setEndDate(newValue);
  };

  useEffect(() => {
    fetchBusinessStatus();
  }, [])

  const applyDateRangeFilter = () => {
    if (startDate && endDate) {
      setFormatLabelSegment('del ' + startDate.format('DD/MM/YYYY') + ' al ' + endDate.format('DD/MM/YYYY'));

      filterByDateRange(startDate, endDate);
    }
    handleCloseCalendarModal();
  };

  const filterByDateRange = (startDate: Dayjs, endDate: Dayjs) => {
    if (statistics) {
      const filtered = statistics.interactions.filter(interaction => {
        const interactionDate = moment(interaction.interaction_date);
        if (!interaction.interaction_date) {
          return;
        }
        return interactionDate.isBetween(startDate.toDate(), endDate.toDate(), undefined, '[]');
      });
      setFilteredInteractions(filtered);
    }

    if (conversations) {
      const filteredConver = conversations.filter(conversation => {
        if (!conversation.last_message_date) {
          return;
        }
        const conversationDate = moment(conversation.last_message_date);
        return conversationDate.isBetween(startDate.toDate(), endDate.toDate(), undefined, '[]');
      });
      setFilteredConversations(filteredConver);

    }
  };

  const handleTabChange = (tab: React.SetStateAction<string>) => {
    setActiveTab(tab);
    if (tab !== 'Range') {
      let startDate: moment.Moment;
      let endDate: moment.Moment;
      switch (tab) {
        case 'Today':
          setFormatLabelSegment('del día de hoy');
          startDate = moment().startOf('day');
          endDate = moment().endOf('day');
          break;
        case 'Week':
          setFormatLabelSegment('de la semana');
          startDate = moment().startOf('week');
          endDate = moment().endOf('week');
          break;
        case 'Month':
          setFormatLabelSegment('del mes');
          startDate = moment().startOf('month');
          endDate = moment().endOf('month');
          break;
        case 'Year':
          setFormatLabelSegment('del año');
          startDate = moment().startOf('year');
          endDate = moment().endOf('year');
          break;
        default:
          setFormatLabelSegment('del día de hoy');
          startDate = moment().startOf('day');
          endDate = moment();
      }

      const filtered = statistics?.interactions.filter(interaction => {
        if (!interaction.interaction_date) {
          return;
        }
        const interactionDate = moment(interaction.interaction_date);
        return interactionDate.isBetween(startDate, endDate);
      }) || [];

      setFilteredInteractions(filtered);

      const filteredConver = conversations?.filter(conversation => {
        if (!conversation.last_message_date) {
          return;
        }
        const conversationDate = moment(conversation.last_message_date);
        return conversationDate.isBetween(startDate, endDate);
      }) || [];

      setFilteredConversations(filteredConver);
    } else {
      handleOpenCalendarModal();
    }
  };

  const fetchConversations = () => {
    axios.get(`${config.backendURL}/get_conversations/${gojimx_businessId}`, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`
      }
    })
      .then(response => {
        setConversations(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the conversations:', error);
      });
  };

  const fetchStatistics = () => {
    axios.get(`${config.backendURL}/get_statistics/${gojimx_businessId}`, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`
      }
    })
      .then(response => {
        setStatistics(response.data[0]);
      })
      .catch(error => {
        console.error('There was an error fetching the statistics:', error);
      });
  };

  useEffect(() => {
    fetchStatistics();
    fetchConversations();
  }, []);

  useEffect(() => {
    if (filteredConversations) {
      const counts = filteredConversations.reduce((acc: { [key: string]: number }, conversation: Conversation) => {
        const category = conversation.category || 'Sin categoría';
        acc[category] = (acc[category] || 0) + 1;
        return acc;
      }, {});
      setCategoryCounts(counts);
    }
  }, [filteredConversations]);

  {/* Chart Stuff */ }
  const chartContainer = useRef<HTMLCanvasElement>(null); // Updated ref type here

  // Number animation
  const animateValue = (start: number, end: number, duration: number, setter: (value: number) => void) => {
    let current = start;
    const range = end - start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / range));

    const timer = setInterval(() => {
      current += increment;
      setter(current);
      if (current === end || (increment > 0 && current >= end) || (increment < 0 && current <= end)) {
        clearInterval(timer);
        setter(end); // Ensure the value is set to the end value
      }
    }, stepTime);

    if (range === 0) {
      setter(end);
    }
  };

  const animateToken = (start: number, end: number, duration: number, setter: (value: number) => void) => {
    let current = start;
    const range = end - start;
    let increment = end > start ? 400 : -400;
    if (Math.abs(range) > 70000) {
      increment *= 4;
    }
    const stepTime = 0.1;
    const timer = setInterval(() => {
      current += increment;
      setter(current);
      if (current === end || (increment > 0 && current >= end) || (increment < 0 && current <= end)) {
        clearInterval(timer);
        setter(end); // Ensure the value is set to the end value
      }
    }, stepTime);

    if (range === 0) {
      setter(end);
    }
  };

  useEffect(() => {
    if (!chartCreated && statistics && conversations) {
      setChartCreated(true);
      handleTabChange('Today');
    }
  }, [statistics, conversations]);

  useEffect(() => {
    // const getStartPoint = (end: number) => (end > 100 ? end - 100 : 0);
    animateValue(interactionsCount ? interactionsCount : 0, filteredInteractions.length, 1000, setInteractionsCount);

    let sumInputToken = 0;
    let sumOutputToken = 0;
    let sumTotalMessages = 0;

    filteredInteractions.map(interaction => {
      sumInputToken += interaction.inputToken ? interaction.inputToken : 0;
      sumOutputToken += interaction.outputToken ? interaction.outputToken : 0;
      sumTotalMessages += interaction.userMessages ? interaction.userMessages : 0;
    })
    animateToken(inputToken ? inputToken : 0, sumInputToken, 10, setInputToken);
    animateToken(outputToken ? outputToken : 0, sumOutputToken, 10, setOutputToken);
    animateValue(totalMessagesCount ? totalMessagesCount : 0, sumTotalMessages, 1000, setTotalMessagesCount);

  }, [filteredInteractions]);

  useEffect(() => {
    const getStartPoint = (end: number) => (end > 100 ? end - 100 : 0);
    animateValue(conversationsCount ? conversationsCount : 0, filteredConversations.length, 1000, setConversationsCount);
  }, [filteredConversations]);

  useEffect(() => {
    if (chartContainer.current && Object.keys(categoryCounts).length > 0) {
      const data = {
        labels: Object.keys(categoryCounts),
        datasets: [{
          label: 'Categorías',
          data: Object.values(categoryCounts),
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 71, 0.6)',
            'rgba(60, 179, 113, 0.6)',
            'rgba(32, 178, 170, 0.6)',
            'rgba(218, 165, 32, 0.6)',
            'rgba(244, 164, 96, 0.6)',
            'rgba(72, 61, 139, 0.6)',
            'rgba(47, 79, 79, 0.6)',
            'rgba(0, 139, 139, 0.6)',
            'rgba(255, 20, 147, 0.6)',
            'rgba(139, 0, 0, 0.6)',
            'rgba(75, 0, 130, 0.6)',
            'rgba(199, 21, 133, 0.6)',
            'rgba(210, 105, 30, 0.6)',
            'rgba(189, 183, 107, 0.6)'
          ],
        }]
      };


      const config: ChartConfiguration<'doughnut', number[], string> = {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'right',
              labels: {
                padding: 20,
                font: {
                  size: 16, // Sets the font size for the legend labels"
                },
                color: 'black'
              }
            },
          },
        },
      };

      const myChart = new Chart(chartContainer.current, config);

      return () => {
        myChart.destroy(); // Cleanup the chart instance on component unmount
      };
    }

  }, [categoryCounts]);

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow} />
      </div>

      <div className='row justify-content-center'>
        <div className='col-lg-8 col-md-8 col-sm-12 py-4'>
          <div className='row px-3 pb-5 animate__animated animate__fadeIn'>
            <div className='d-flex'>
              <h1 className='fw-bold text-dark mt-0 me-3'>Estadísticas</h1>
            </div>
            <div className='row p-0 mx-0 mb-0 mt-2'>
              <div className='col-12 d-flex justify-content-between align-items-center'>
                <div className='animate__animated animate__fadeIn mb-3 pe-0 ps-3'>
                  <ul className="nav">
                    <li className="nav-item border-radius-custom-1 bg-light">
                      <button
                        className={activeTab == "Today" ? 'btn btn-lg border-0 btn-dark rounded-pill w-150-px' : 'btn btn-lg border-0 btn-light rounded-pill w-150-px'}
                        onClick={() => handleTabChange('Today')}
                      >Hoy</button>
                    </li>
                    <li className="nav-item bg-light">
                      <button
                        className={activeTab == "Week" ? 'btn btn-lg border-0 btn-dark rounded-pill w-150-px' : 'btn btn-lg border-0 btn-light rounded-pill w-150-px'}
                        onClick={() => handleTabChange('Week')}
                      >Semana</button>
                    </li>
                    <li className="nav-item bg-light">
                      <button
                        className={activeTab == "Month" ? 'btn btn-lg border-0 btn-dark rounded-pill w-150-px' : 'btn btn-lg border-0 btn-light rounded-pill w-150-px'}
                        onClick={() => handleTabChange('Month')}
                      >Mes</button>
                    </li>
                    <li className="nav-item bg-light">
                      <button
                        className={activeTab == "Year" ? 'btn btn-lg border-0 btn-dark rounded-pill w-150-px' : 'btn btn-lg border-0 btn-light rounded-pill w-150-px'}
                        onClick={() => handleTabChange('Year')}
                      >Año</button>
                    </li>
                    <li className="nav-item border-radius-custom-2 bg-light">
                      <button
                        className={activeTab == "Range" ? 'btn btn-lg border-0 btn-dark rounded-pill w-150-px' : 'btn btn-lg border-0 btn-light rounded-pill w-150-px'}
                        onClick={() => handleTabChange('Range')}
                      >Rango</button>
                    </li>
                  </ul>
                </div>
                <div className='animate__animated animate__fadeIn mb-3'>
                  <button className='btn btn-light rounded-pill' onClick={() => { filteredInteractions ? exportToExcelStatistics(filteredInteractions) : alert('Sin información') }}>
                    Descargar <i className="ms-1 lead bi bi-file-earmark-arrow-down"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className='w-100 my-4'>
              <div className='border mb-1 animate__animated animate__fadeIn'></div>
            </div>


            {/* CONTADORES */}
            <div className='d-flex'>
              <h2 className='fw-bold text-dark mt-0 me-3'>Contadores</h2>
              <p className='lead mt-1 p-0 text-secondary'>Input tokens: {inputToken} - Output tokens: {outputToken}</p>
            </div>

            <div className="row">
              <div className="col-4 text-center border-2 border-end">
                <h1 className='display-1 fw-bold m-0 p-0'>{conversationsCount}</h1>
                <p className='lead m-0 p-0'>Conversaciones</p>
              </div>
              <div className="col-4 text-center border-2 border-end">
                <h1 className='display-1 fw-bold m-0 p-0'>{interactionsCount}</h1>
                <p className='lead m-0 p-0'>Interacciones</p>
              </div>
              <div className="col-4 text-center">
                <h1 className='display-1 fw-bold m-0 p-0'>{totalMessagesCount}</h1>
                <p className='lead m-0 p-0'>Mensajes Enviados</p>
              </div>
            </div>

            <Modal size="lg" show={showCalendarModal} onHide={handleCloseCalendarModal} className='modal-dates'>
              <Modal.Header closeButton>
                <Modal.Title className='fw-bold'>📅 Seleccione un rango de fechas</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='d-flex flex-column flex-lg-row flex-md-col flex-sm-col justify-content-between'>
                  <div className='mb-3 mb-md-0 text-center'>
                    <p className='lead m-0 p-0'>Fecha inicial</p>
                    <DateCalendar
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </div>
                  <div className='mb-3 mb-md-0 text-center'>
                    <p className='lead m-0 p-0'>Fecha final</p>
                    <DateCalendar
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-end mt-3'>
                  <Button variant="primary" onClick={applyDateRangeFilter}>
                    Aplicar
                  </Button>
                </div>
              </Modal.Body>
            </Modal>

            {/* Chart */}
            <div className='d-block mt-5 mb-2 chart-container'>
              <h2 className='fw-bold text-dark mt-0 me-3'>Segmentación por categoría</h2>
              <p className='lead m-0 p-0 text-secondary'>Distribución de las conversaciones {formatLabelSegment}</p>
            </div>
            <div className='row d-flex px-3 justify-content-center animate__animated animate__fadeIn'>
              {/* Chart container */}
              <div className='col-10 chart-container'>
                <canvas ref={chartContainer}></canvas>
              </div>
            </div>


            {/* <div className='d-flex mt-3'> */}
              {/* KeyWords
              <h2 className='fw-bold text-dark mt-0 me-3'>Palabras Clave</h2>
            </div>

            <div className='row ps-3 my-4'>
              <div className='col-4 px-2 mt-0 border-end border-2 '>
                <p className='lead btn btn-outline-light text-dark border-0 w-100'>Categoría A</p>
                <p className='lead btn btn-outline-light text-dark border-0 w-100'>Categoría B</p>
                <p className='lead btn btn-outline-light text-dark border-0 w-100'>Categoría C</p>
                <p className='lead btn btn-outline-light text-dark border-0 w-100'>Categoría D</p>
                <p className='lead btn btn-outline-light text-dark border-0 w-100'>Categoría E</p>
              </div>
              <div className='col-8 px-2 mt-0'>
                <h3 className='display-1 px-5'>Hola Mundo!</h3>
                <h3 className='display-3 px-5'>Hola Mundo!</h3>
                <h3 className='display-5 px-5'>Hola Mundo!</h3>

              </div>
            </div> */}
          </div>
        </div>
      </div>



      <Menu show={show} handleClose={handleClose} />
    </div>

  );
}

export default Statistics;
