import * as XLSX from 'xlsx';
import { formatPhoneNumber } from './functions';

interface Conversation {
    summary?: string;
    user_name: string;
    user_phone: string;
}
interface Interaction {
    _id: string;
    interaction_phone: string;
    interaction_date: string;
    thread_id: string;
    inputToken: number;
    outputToken: number;
    userMessages: number;
  }
  
  interface Message {
    _id: string;
    message_phone: string;
    message_date: string;
    thread_id: string;
  }
  
  interface Statistics {
    _id: string;
    business_id: string;
    interactions: Interaction[];
    messages: Message[];
  }

export const exportExampleFormat = (): void => {
    const exampleData = [{
        user_name: "Juan Pérez",
        user_phone: "5210000000000"
    }];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exampleData, {
        header: ["user_name", "user_phone"],
        skipHeader: false 
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, "FormatoRenata");

    const writingOptions: XLSX.WritingOptions = { bookType: 'xlsx', type: 'binary' };
    const workbookBinary = XLSX.write(workbook, writingOptions);

    const blob = new Blob([s2ab(workbookBinary)], { 
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const fileURL = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.setAttribute('download', 'Formato_Ejemplo_Renata.xlsx');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};


function s2ab(s: string): ArrayBuffer {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i=0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export const exportToExcel = (conversations: Conversation[]): void => {
    const filteredData = conversations.map(({ summary, user_name, user_phone }) => ({
        summary,
        user_name,
        user_phone,
    }));
  
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Conversations");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    const fileURL = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'Conversations.xlsx'); // Nombre del archivo a descargar
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const exportToExcelStatistics = (interactions: Interaction[]): void => {
    const filteredData = interactions.map(({ interaction_phone, interaction_date, inputToken, outputToken, userMessages }) => ({
      Teléfono : formatPhoneNumber(interaction_phone),
      Fecha: new Date(interaction_date).toLocaleString(),
      'Tokens de entrada' : inputToken,
      'Tokens de salida' : outputToken,
      'Mensajes del usuario' : userMessages,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Interactions");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    const fileURL = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'Interactions.xlsx'); // Nombre del archivo a descargar
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


export const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    callback: (data: any[]) => void // Añade un parámetro callback
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      callback(jsonData); // Usa el callback aquí
    };
    reader.readAsBinaryString(file);
};