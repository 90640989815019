import React, { useState, ChangeEvent } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Logo from './img/renata-logo.svg';
import loadingGif from './img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/login.css';
import axios from 'axios';
import ConfirmationModal from './Components/Modals/ConfirmationModal'; // Asegúrate de que la ruta sea correcta
import config from './config/config';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get('token');

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setModalText('');

    if (!password) {
      setPasswordError(true);
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      setIsLoading(false);
      return;
    }

    try {
      await axios.post(`${config.backendURL}/recover_password`, { token, newPassword: password, confirmPassword });
      setModalText('Tu contraseña ha sido actualizada correctamente.');
      setModalType('✅');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 500) {
          setModalText('Error interno del servidor, por favor inténtalo nuevamente más tarde.');
          setModalType('❌');
        } else {
          setModalText('Error al actualizar la contraseña. Por favor verifica el enlace de recuperación.');
          setModalType('❌');
        }
      } else {
        setModalText('Se produjo un error, por favor verifica tu conexión a internet o inténtalo nuevamente más tarde.');
        setModalType('❌');
      }
    } finally {
      setIsLoading(false);
      setModalShow(true);
    }
  };

  return (
    <div className='flex-column align-items-center py-3 px-5'>
      <div className='row justify-content-center'>
        <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
          <img className="w-50 mt-5" src={Logo} alt="Renata Logo" />
        </div>
      </div>
      
      <div className='row justify-content-center'>
        <div className="col-sm-12 col-md-4 col-lg-3 p-login">
          <h1 className='fw-bolder text-dark text-center mb-4 animate__animated animate__fadeInUp'>Restablecer Contraseña</h1>
          <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
            <div className="mb-3">
              <input 
                type="password" 
                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${passwordError ? 'is-invalid' : ''}`}
                placeholder='Nueva Contraseña' 
                onChange={handlePasswordChange}
                autoComplete="off"
                value={password}
              />
              {passwordError && <div className="invalid-feedback">Por favor, introduce una nueva contraseña.</div>}
            </div>
            <div className="mb-3">
              <input 
                type="password" 
                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${confirmPasswordError ? 'is-invalid' : ''}`}
                placeholder='Confirmar Contraseña' 
                onChange={handleConfirmPasswordChange}
                autoComplete="off"
                value={confirmPassword}
              />
              {confirmPasswordError && <div className="invalid-feedback">Las contraseñas no coinciden.</div>}
            </div>
            <button type="submit" className="btn btn-dark w-100" disabled={isLoading}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : 'Enviar'}
            </button>
            <div className='mt-2 text-center'>
              <span className='fs-6'>¿No tienes una cuenta? <Link to="/register" className="text-primary">Regístrate</Link></span>
            </div>
            <div className='text-center'>
              <span className='fs-6'>¿Ya tienes una cuenta? <Link to="/login" className="text-primary">Inicia sesión</Link></span>
            </div>
          </form>
        </div>
      </div>

      <ConfirmationModal
        show={modalShow}
        onHide={() => navigate('/login')}
        text={modalText}
        type={modalType}
        isLoading={isLoading}
        titleText='Restablecer Contraseña'
      />
    </div>
  );
}

export default ResetPassword;