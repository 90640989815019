// Components/EditUserModal.tsx
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

interface User {
    id: number;
    username: string,
    phoneNumber: string,
    email: string
}

interface UsersEditModalProps {
    show: boolean;
    onHide: () => void;
    user: User | null;
    onSave: (updatedUser: User) => void;
}


const UsersEditModal: React.FC<UsersEditModalProps> = ({ show, user, onHide, onSave }) => {
    const gojimx_token = localStorage.getItem('gojimx_token');
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const [formData, setFormData] = useState<User | null>(null);

    useEffect(() => {
        if (user) {
            setFormData(user);
        }
    }, [user]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof User) => {
        if (formData) {
            setFormData({ ...formData, [field]: e.target.value });
        }
    };

    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        if (formData) {
            try {
                //const response = await axios.post(`${config.backendURL}/}`, {
                const response = await axios.post('http://localhost:3333/backend/updateSecondaryUser', {
                    businessId: gojimx_businessId,
                    id: formData.id,
                    username: formData.username,
                    phoneNumber: formData.phoneNumber,
                    email: formData.email
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${gojimx_token}`
                    }
                });
                if (response.status === 200) {
                    console.log(response.data);
                    onSave(formData);
                    onHide();
                }

            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.message;
                        console.log(getError);

                    } else if (error.request) {
                        console.log('Request made but no response received:', error.request);
                    } else {
                        console.log('Error message:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            }
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Usuario</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSave}>
                <Modal.Body>
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData?.username || ''}
                            onChange={(e) => handleChange(e, 'username')}
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Correo</Form.Label>
                        <Form.Control
                            type="email"
                            value={formData?.email || ''}
                            onChange={(e) => handleChange(e, 'email')}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPhone">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData?.phoneNumber || ''}
                            onChange={(e) => handleChange(e, 'phoneNumber')}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onHide}>
                        Cancelar
                    </Button>
                    <Button variant="dark" onClick={handleSave}>
                        Guardar Cambios
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UsersEditModal;