import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import loadingGif from '../../img/loading.gif'

interface ConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  text: string;
  type: string;
  isLoading?: boolean;
  titleText: string;
  onClick?: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, onHide, text, type, isLoading = false, titleText = 'Configuración', onClick = () => {} }) => {
  return (
    <Modal  show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{type} {titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {titleText !== 'Estimación' && titleText !== 'Eliminar archivo' ? (
        <>
          {text}
          {isLoading && <img className='loading' src={loadingGif} alt="Cargando..." />}
        </>
      ):(
        <>
          {text}
          {isLoading}
        </>
      )}
      </Modal.Body>
      <Modal.Footer>
        {titleText === 'Estimación' &&
          <>
            <Button variant="light" onClick={onHide} disabled={isLoading}>
              Cancelar
            </Button>
            <Button variant="dark" onClick={onClick}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Continuar'}
            </Button>
          </>
        }
        {titleText === 'Eliminar archivo' &&
          <>
            <Button variant="light" onClick={onHide} disabled={isLoading}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={onClick}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Eliminar'}
            </Button>
          </>
        }
        {titleText === 'Base de conocimiento en uso' &&
          <>
            <Button variant="danger" onClick={onClick}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Eliminar'}
            </Button>
          </>
        }
        {titleText !== 'Estimación' && titleText !== 'Eliminar archivo' && (
          <Button variant="dark" onClick={onHide} disabled={isLoading}>
            Cerrar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;