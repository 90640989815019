import React, { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import ReactJson, { InteractionProps } from 'react-json-view';
import loadingGif from '../../img/loading.gif';

interface Skill {
    skill_type: string;
    skill_name: string;
    skill_description: string;
    skill_id: string;
    skill_properties: {
        [key: string]: any;

    };
}

interface EditWebhookSkillModalProps {
    show: boolean;
    onHide: () => void;
    data: Skill;
    onSave: (updatedData: Skill) => void;
    isLoading: boolean;
    onDelete: (updatedData: Skill) => void;
    isLoadingEditSkill: boolean;

}

const EditWebhookSkillModal: React.FC<EditWebhookSkillModalProps> = ({ show, onHide, data, onSave, isLoading, onDelete, isLoadingEditSkill}) => {
    const [formData, setFormData] = useState<Skill>(data);

    useEffect(() => {
        setFormData(data);
    }, [data]);

    const handleSave = async () => {
        await onSave(formData);
        onHide();
    };

    const handleJsonChange = (edit: InteractionProps): void => {
        setFormData({ ...formData, skill_properties: { ...formData.skill_properties, body: edit.updated_src } });
    };

    const handleParametroChange = (index: number, field: keyof Skill['skill_properties']['parameters'][0], value: string) => {
        const updatedParametros = [...formData.skill_properties.parameters];
        updatedParametros[index][field] = value;
        setFormData({ ...formData, skill_properties: { ...formData.skill_properties, parameters: updatedParametros } });
    };

    const addParametro = () => {
        setFormData({
            ...formData,
            skill_properties: {
                ...formData.skill_properties,
                parameters: [...formData.skill_properties.parameters, { value: '', type: 'string', description: '' }],
            },
        });
    };

    const removeParametro = (index: number) => {
        const updatedParametros = formData.skill_properties.parameters.filter((_: any, i: number) => i !== index);
        setFormData({ ...formData, skill_properties: { ...formData.skill_properties, parameters: updatedParametros } });
    };

    const handleDelete= async () => {
        await onDelete(formData);
        onHide();
    }

    return (
        <Modal show={show} onHide={onHide} size='lg' centered>
            <Modal.Header closeButton>
                <Modal.Title className='ps-3'>
                    <h4 className='fw-bold'>Editar Habilidad: WebHook</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="p-3">
                    <Form.Group controlId="habilidadName" className="mb-4">
                        <Form.Label className="fw-bold">Identificador de la habilidad</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.skill_name}
                            onChange={(e) => setFormData({ ...formData, skill_name: e.target.value })}
                            placeholder="Introduce el nombre de la habilidad"
                        />
                    </Form.Group>

                    <Form.Group controlId="habilidadDescription" className="mb-4">
                        <Form.Label className="fw-bold">Descripción de la habilidad</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.skill_description}
                            onChange={(e) => setFormData({ ...formData, skill_description: e.target.value })}
                            placeholder="Describe la habilidad"
                        />
                    </Form.Group>

                    <Form.Group controlId="methodUrl" className="mb-4">
                        <div className="row">
                            <div className="col-md-2">
                                <Form.Label className="fw-bold">Método</Form.Label>
                                <Form.Select
                                    value={formData.skill_properties.method}
                                    onChange={(e) => setFormData({ ...formData, skill_properties: { ...formData.skill_properties, method: e.target.value } })}
                                >
                                    <option value="GET">GET</option>
                                    <option value="POST">POST</option>
                                    <option value="PUT">PUT</option>
                                    <option value="DELETE">DELETE</option>
                                </Form.Select>
                            </div>
                            <div className="col-md-10">
                                <Form.Label className="fw-bold">URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formData.skill_properties.url}
                                    onChange={(e) => setFormData({ ...formData, skill_properties: { ...formData.skill_properties, url: e.target.value } })}
                                    placeholder="Introduce la URL"
                                />
                            </div>
                        </div>
                    </Form.Group>

                    <Form.Group controlId="parametros" className="mb-4">
                        <Form.Label className="fw-bold">Parámetros</Form.Label>
                        <Button variant="dark" className="mx-2" onClick={addParametro}>
                            Agregar
                        </Button>
                        {formData.skill_properties.parameters.map((parametro: any, index: number) => (
                            <div key={index} className="row my-2">
                                <div className="col-md-4">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nombre del parámetro"
                                        value={parametro.value}
                                        onChange={(e) => handleParametroChange(index, 'value', e.target.value)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <Form.Select
                                        value={parametro.type}
                                        onChange={(e) => handleParametroChange(index, 'type', e.target.value)}
                                    >
                                        <option value="string">string</option>
                                        <option value="number">number</option>
                                        <option value="boolean">boolean</option>
                                    </Form.Select>
                                </div>
                                <div className="col-md-4">
                                    <Form.Control
                                        type="text"
                                        placeholder="Descripción"
                                        value={parametro.description}
                                        onChange={(e) => handleParametroChange(index, 'description', e.target.value)}
                                    />
                                </div>
                                <div className="col-md-1">
                                    <Button variant="danger" onClick={() => removeParametro(index)}>
                                        Eliminar
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </Form.Group>

                    <Form.Group controlId="bodyJson" className="mb-4">
                        <Form.Label className="fw-bold">Body (JSON)</Form.Label>
                        <ReactJson
                            src={formData.skill_properties.body}
                            onEdit={handleJsonChange}
                            onAdd={handleJsonChange}
                            onDelete={handleJsonChange}
                            style={{ backgroundColor: 'white', color: 'black', borderRadius: 10, padding: 20 }} // Custom styles
                        />
                    </Form.Group>

                    <Form.Group controlId="authorization" className="mb-4">
                        <Form.Label className="fw-bold">Autorización</Form.Label>
                        <div className="row">
                            <div className="col-md-2">
                                <Form.Label>Tipo</Form.Label>
                                <Form.Select
                                    value={formData.skill_properties.authorizationType}
                                    onChange={(e) => setFormData({ ...formData, skill_properties: { ...formData.skill_properties, authorizationType: e.target.value } })}
                                >
                                    <option value="Ninguno">Ninguno</option>
                                    <option value="Bearer">Bearer</option>
                                    <option value="Basic">Basic</option>
                                    <option value="OAuth">OAuth</option>
                                    <option value="ApiKey">API Key</option>
                                </Form.Select>
                            </div>
                            <div className="col-md-10">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control
                                    disabled={formData.skill_properties.authorizationType === 'Ninguno'}
                                    type="text"
                                    value={formData.skill_properties.authorizationValue}
                                    onChange={(e) => setFormData({ ...formData, skill_properties: { ...formData.skill_properties, authorizationValue: e.target.value } })}
                                    placeholder="Introduce el valor de autorización"
                                />
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>
                    {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Borrar'}
                </Button>
                <Button variant="light" onClick={onHide}>
                    Cancelar
                </Button>
                <Button variant="dark" onClick={handleSave}>
                    {isLoadingEditSkill ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditWebhookSkillModal;