import React, { useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import Logo from './img/renata-logo.svg';
import loadingGif from './img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/login.css';
import axios from 'axios';
import ConfirmationModal from './Components/Modals/ConfirmationModal'; // Asegúrate de que la ruta sea correcta
import { useNavigate } from 'react-router-dom';
import config from './config/config';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setEmailError(false);
    setModalText('');

    // Validación simple del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${config.backendURL}/send_email_password_recover`, { email });
      setModalText('Si algún negocio coincide con esa cuenta de correo, se te enviará un correo con un enlace para recuperar tu contraseña.');
      setModalType('✅');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 500) {
            setModalType('❌');
          setModalText('Error interno del servidor, por favor inténtalo nuevamente más tarde.');
        } else {
            setModalText('Si algún negocio coincide con esa cuenta de correo, se te enviará un correo con un enlace para recuperar tu contraseña.');
            setModalType('✅');       
         }
      } else {
        setModalType('❌');
        setModalText('Se produjo un error, por favor verifica tu conexión a internet o inténtalo nuevamente más tarde.');
      }
    } finally {
      setIsLoading(false);
      setModalShow(true);
    }
  };

  return (
    <div className='flex-column align-items-center py-3 px-5'>
      <div className='row justify-content-center'>
        <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
          <img className="w-50 mt-5" src={Logo} alt="Renata Logo" />
        </div>
      </div>
      
      <div className='row justify-content-center'>
        <div className="col-sm-12 col-md-4 col-lg-3 p-login">
          <h1 className='fw-bolder text-dark text-center mb-4 animate__animated animate__fadeInUp'>Recuperar Contraseña</h1>
          <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
            <div className="mb-3">
              <input 
                type="email" 
                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${emailError ? 'is-invalid' : ''}`}
                placeholder='Correo Electrónico' 
                onChange={handleEmailChange}
                autoComplete="off"
                value={email}
              />
              {emailError && <div className="invalid-feedback">Por favor, introduce un correo electrónico válido.</div>}
            </div>
            <button type="submit" className="btn btn-dark w-100" disabled={isLoading}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..."/> : 'Enviar'}
            </button>
            <div className='mt-2 text-center'>
              <span className='fs-6'>¿No tienes una cuenta? <Link to="/register" className="text-primary">Regístrate</Link></span>
            </div>
            <div className='text-center'>
              <span className='fs-6'>¿Ya tienes una cuenta? <Link to="/login" className="text-primary">Inicia sesión</Link></span>
            </div>
          </form>
        </div>
      </div>

      <ConfirmationModal
        show={modalShow}
        onHide={() => navigate('/Login')}
        text={modalText}
        type={modalType}
        isLoading={isLoading}
        titleText='Recuperar Contraseña'
      />
    </div>
  );
}

export default ForgotPassword;