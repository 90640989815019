import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import loadingGif from '../../img/loading.gif';
import WhatsAppLogo from '../../img/WhatsApp_Logo.png';
import InstagramLogo from '../../img/Instagram_Logo.png';
import LinkedinLogo from '../../img/Linkedin_Logo.png';
import XLogo from '../../img/X_Logo.png';
import MessengerLogo from '../../img/Messenger_Logo.png';
import config from '../../config/config';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import QRCode from 'react-qr-code';

interface AddChannelModalProps {
    show: boolean;
    onHide: () => void;
    isLoading?: boolean;
    fetchBusiness: () => void
}

const AddChannelModal: React.FC<AddChannelModalProps> = ({ show, onHide, isLoading = false, fetchBusiness }) => {
    const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [phoneError, setPhoneError] = useState(false);
    const [isValidNumber, setIsValidNumber] = useState(false);
    const [savingChannel, setSavingChannel] = useState(false);
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('');
    const [confirmationModalType, setConfirmationModalType] = useState('');
    const [channelAdded, setChannelAdded] = useState(false);
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const gojimx_token = localStorage.getItem('gojimx_token');
    const [formatedPhoneNumber, setFormatedPhoneNumber] = useState('');
    const [qrCode, setQrCode] = useState<string>('');
    const [port, setPort] = useState(0);


    const channels = [
        { name: 'WhatsApp', logo: WhatsAppLogo, background: '#bbe5c6' },
        // { name: 'Instagram', logo: InstagramLogo, background: '#f9dbc6' },
        // { name: 'Messenger', logo: MessengerLogo, background: '#c9daed' },
        // { name: 'LinkedIn', logo: LinkedinLogo, background: '#cbdbef' },
        // { name: 'X', logo: XLogo, background: '#4d4d4d' },
    ];

    const getLogoForChannel = (channelName: string | null) => {
        const channel = channels.find(ch => ch.name === channelName);
        return channel ? channel.logo : '';
    };

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value.replace(/\D/g, '');
        const prefix = value.slice(0, 2);
        if (['55', '56', '33', '81'].includes(prefix)) {
            if (value.length > 2 && value.length <= 6) {
                value = value.slice(0, 2) + ' ' + value.slice(2);
            } else if (value.length > 6) {
                value = value.slice(0, 2) + ' ' + value.slice(2, 6) + ' ' + value.slice(6, 10);
            }
        } else {
            if (value.length > 3 && value.length <= 6) {
                value = value.slice(0, 3) + ' ' + value.slice(3);
            } else if (value.length > 6) {
                value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 10);
            }
        }

        setPhoneNumber(value);
        setPhoneError(false);
        setIsValidNumber(value.length === 12);
    };

    const handleChannelClick = (channelName: string) => {
        setSelectedChannel(channelName);
    };

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // setPort(0);
        // setChannelAdded(true);

        // return;
        if (!isValidNumber) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
            setSavingChannel(true);
            try {
                const response = await axios.post(`${config.backendURL}/add_whatsapp_channel`,
                    {
                        business_id: gojimx_businessId,
                        phoneNumber: phoneNumber,
                        channel_name: selectedChannel
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${gojimx_token}`
                        }
                    });
                setFormatedPhoneNumber(response.data.phoneNumber);
                // Handle the response as needed
                // setConfirmationModalText('Se agregó el canal de WhatsApp correctamente, por favor, de clic sobre el canal para conectar su WhatsApp');
                // setConfirmationModalType('✅');
                // setConfirmationModalShow(true);
                // onHide(); // Removed to keep the modal open for the third step
            } catch (error) {
                setConfirmationModalText('No se ha podido guardar el canal de WhatsApp');
                setConfirmationModalType('❌');
                setConfirmationModalShow(true);
                setSavingChannel(false);
            }
        }
    };

    const handleBackClick = () => {
        setSelectedChannel(null);
        setPhoneNumber('');
        setPhoneError(false);
    };



    useEffect(() => {

        const handleConnectWhatsapp = async () => {
            if (formatedPhoneNumber) {
                try {
                    const createBaileysResponse = await axios.post(`${config.apiBaileys}/createRenataBaileysOnChannel`, {
                        business_id: gojimx_businessId,
                        username: formatedPhoneNumber
                    }, {
                        headers: {
                            Authorization: `Bearer ${gojimx_token}`
                        }
                    });

                    setPort(createBaileysResponse.data.baileys_port);
                    setSavingChannel(false);
                    setChannelAdded(true);
                    fetchBusiness();
                } catch (error) {
                    console.error('Error fetching Baileys status and port:', error);
                }
            }
        };

        if (formatedPhoneNumber.length > 0) {
            handleConnectWhatsapp();

        }
    }, [formatedPhoneNumber])

    // useEffect(() => {
    //     const fetchQrCode = async () => {
    //         try {
    //             const response = await axios.get(`${config.apiBaileys}/${port}/getQR`, {
    //                 headers: {
    //                     Authorization: `Bearer ${gojimx_token}`
    //                 }
    //             });
    //             setQrCode(response.data);
    //         } catch (error) {
    //             console.error('Error fetching QR code:', error);
    //         }
    //     }

    //     if (port != 0) {
    //         const intervalId = setInterval(fetchQrCode, 50000); // Fetch QR code every 50 seconds
    //         fetchQrCode(); // Initial fetch
    //     }
    // }, [port])

    const closeModal = () => {
        onHide();
        setSelectedChannel(null);
        setPhoneNumber('');
        setPhoneError(false);
        setPort(0);
        setChannelAdded(false);
    }

    return (
        <>
            <Modal show={show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <div className='col'>
                        {selectedChannel && <img src={getLogoForChannel(selectedChannel)} alt="icon" className="" style={{ width: '48px', height: '48px' }} />}
                        <Modal.Title>{selectedChannel ? `Añadir ${selectedChannel}` : 'Añadir nuevo canal'}</Modal.Title>
                        {!selectedChannel && <p>Selecciona el tipo de canal que quieres conectar a Renata</p>}
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        {!selectedChannel ? (
                            <div className='row'>
                                {channels.map((channel, index) => (
                                    <div
                                        key={index}
                                        className="col-4 mb-3 d-flex justify-content-center"
                                        onClick={() => handleChannelClick(channel.name)}
                                    >
                                        <div className="card rounded-5 d-flex align-items-center px-5 py-1" style={{ backgroundColor: channel.background }}>
                                            <img src={channel.logo} alt="icon" className="" style={{ width: '40px', height: '40px' }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : !channelAdded ? (
                            <Form onSubmit={handleFormSubmit} className='animate__animated animate__fadeIn'>
                                <Form.Group controlId="formPhoneNumber">
                                    <Form.Label>Número de Teléfono</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Ingresa el número de teléfono"
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                        isInvalid={phoneError}
                                        required
                                    />
                                    {phoneError && <Form.Control.Feedback type="invalid">Por favor, introduce un teléfono válido.</Form.Control.Feedback>}
                                </Form.Group>
                            </Form>
                        ) : (
                            <div className='animate__animated animate__fadeIn'>

                                <>
                                    <p>Se agregó el canal de WhatsApp correctamente, por favor, de clic sobre el canal para conectar su WhatsApp.</p>

                                </>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {savingChannel ? (
                        <div className='ms-auto me-3'>
                            <h1 style={{ fontWeight: 'bold' }}><img className='loading' src={loadingGif} alt="Cargando..." /></h1>
                        </div>
                    ) : (
                        <>
                            {selectedChannel && !channelAdded ? (
                                <>
                                    <Button className='animate__animated animate__fadeIn' variant="dark" onClick={handleBackClick}>
                                        Atrás
                                    </Button>
                                    <Button className='animate__animated animate__fadeIn' variant="dark" onClick={handleFormSubmit}>
                                        Guardar
                                    </Button>
                                </>
                            ) :
                                <Button variant="dark" onClick={closeModal}>
                                    Cerrar
                                </Button>
                            }
                        </>
                    )}
                </Modal.Footer>
            </Modal>
            <ConfirmationModal
                show={confirmationModalShow}
                onHide={() => setConfirmationModalShow(false)}
                text={confirmationModalText}
                type={confirmationModalType}
                titleText="Resultado de la Operación"
            />
        </>
    );
};

export default AddChannelModal;