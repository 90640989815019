import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import ReactJson, { InteractionProps } from 'react-json-view';

interface Parametro {
  value: string;
  type: string;
  description: string;
}

interface WebhookFormProps {
  data: {
    habilidadName: string;
    habilidadDescription: string;
    method: string;
    url: string;
    parametros: Parametro[];
    jsonData: Record<string, any>;
    authorizationType: string;
    authorizationValue: string;
  };
  onChange: (data: WebhookFormProps['data']) => void;
}

const WebHookForm: React.FC<WebhookFormProps> = ({ data, onChange }) => {
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    onChange(formData); // Llamar onChange cada vez que formData cambie
  }, [formData]);

  useEffect(() => {
    setFormData(data); // Sincroniza formData con los datos recibidos cuando cambian
  }, [data]);
  
  const handleJsonChange = (edit: InteractionProps): void => {
    setFormData({ ...formData, jsonData: edit.updated_src });
  };

  const handleParametroChange = (index: number, field: keyof Parametro, value: string) => {
    const updatedParametros = [...formData.parametros];
    updatedParametros[index][field] = value;
    setFormData({ ...formData, parametros: updatedParametros });
  };

  const addParametro = () => {
    setFormData({
      ...formData,
      parametros: [...formData.parametros, { value: '', type: 'string', description: '' }],
    });
  };

  const removeParametro = (index: number) => {
    const updatedParametros = formData.parametros.filter((_, i) => i !== index);
    setFormData({ ...formData, parametros: updatedParametros });
  };

  return (
    <Form className="p-3">
      <Form.Group controlId="habilidadName" className="mb-4">
        <Form.Label className="fw-bold">Identificador de la habilidad</Form.Label>
        <Form.Control
          type="text"
          value={formData.habilidadName}
          onChange={(e) => setFormData({ ...formData, habilidadName: e.target.value })}
          placeholder="Introduce el nombre de la habilidad"
        />
      </Form.Group>

      <Form.Group controlId="habilidadDescription" className="mb-4">
        <Form.Label className="fw-bold">Descripción de la habilidad</Form.Label>
        <Form.Control
          type="text"
          value={formData.habilidadDescription}
          onChange={(e) => setFormData({ ...formData, habilidadDescription: e.target.value })}
          placeholder="Describe la habilidad"
        />
      </Form.Group>

      <Form.Group controlId="methodUrl" className="mb-4">
        <div className="row">
          <div className="col-md-2">
            <Form.Label className="fw-bold">Método</Form.Label>
            <Form.Select
              value={formData.method}
              onChange={(e) => setFormData({ ...formData, method: e.target.value })}
            >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
            </Form.Select>
          </div>
          <div className="col-md-10">
            <Form.Label className="fw-bold">URL</Form.Label>
            <Form.Control
              type="text"
              value={formData.url}
              onChange={(e) => setFormData({ ...formData, url: e.target.value })}
              placeholder="Introduce la URL"
            />
          </div>
        </div>
      </Form.Group>

      <Form.Group controlId="parametros" className="mb-4">
        <Form.Label className="fw-bold">Parámetros</Form.Label>
        <Button variant="dark" className="mx-2" onClick={addParametro}>
          Agregar
        </Button>
        {formData.parametros.map((parametro, index) => (
          <div key={index} className="row my-2">
            <div className="col-md-4">
              <Form.Control
                type="text"
                placeholder="Nombre del parámetro"
                value={parametro.value}
                onChange={(e) => handleParametroChange(index, 'value', e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <Form.Select
                value={parametro.type}
                onChange={(e) => handleParametroChange(index, 'type', e.target.value)}
              >
                <option value="string">string</option>
                <option value="number">number</option>
                <option value="boolean">boolean</option>
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Control
                type="text"
                placeholder="Descripción"
                value={parametro.description}
                onChange={(e) => handleParametroChange(index, 'description', e.target.value)}
              />
            </div>
            <div className="col-md-1">
              <Button variant="danger" onClick={() => removeParametro(index)}>
                Eliminar
              </Button>
            </div>
          </div>
        ))}
      </Form.Group>

      <Form.Group controlId="bodyJson" className="mb-4">
        <Form.Label className="fw-bold">Body (JSON)</Form.Label>
        <ReactJson
          src={formData.jsonData}
          onEdit={handleJsonChange}
          onAdd={handleJsonChange}
          onDelete={handleJsonChange}
          style={{ backgroundColor: 'white', color: 'black', borderRadius: 10, padding: 20 }} // Custom styles
          />
      </Form.Group>

      <Form.Group controlId="authorization" className="mb-4">
        <Form.Label className="fw-bold">Autorización</Form.Label>
        <div className="row">
          <div className="col-md-2">
            <Form.Label>Tipo</Form.Label>
            <Form.Select
              value={formData.authorizationType}
              onChange={(e) => setFormData({ ...formData, authorizationType: e.target.value })}
            >
              <option value="Ninguno">Ninguno</option>
              <option value="Bearer">Bearer</option>
              <option value="Basic">Basic</option>
              <option value="OAuth">OAuth</option>
              <option value="ApiKey">API Key</option>
            </Form.Select>
          </div>
          <div className="col-md-10">
            <Form.Label>Valor</Form.Label>
            <Form.Control
              disabled={formData.authorizationType === 'Ninguno' ? true : false}
              type="text"
              value={formData.authorizationValue}
              onChange={(e) => setFormData({ ...formData, authorizationValue: e.target.value })}
              placeholder="Introduce el valor de autorización"
            />
          </div>
        </div>
      </Form.Group>
    </Form>
  );
};

export default WebHookForm;