import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import handleLogout from './functions/functions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PoweredByStripe from './img/powered_by_stripe.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import config from './config/config';

const stripePromise = loadStripe('pk_live_51ORmOjFziCzDV6ZvPCxIUUmHkDm1eyxvvKDyfVS0Odlp52fgTFDfmGGzwaJjbt3E5WAfu7W3vYwI7sFKZwzK9cIu00oYGKSSTu');

const CheckoutForm: React.FC<{ clientSecret: string }> = ({ clientSecret }) => {
    const [gojimxName, setGojimxName] = useState('');

    useEffect(() => {
        setGojimxName(String(localStorage.getItem('gojimx_username')));
    }, []);

    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState<string | null>(null);
    const [processing, setProcessing] = useState(false);
    const [succeeded, setSucceeded] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setProcessing(true);

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement!
            },
        });

        if (payload.error) {
            setError(`Pago fallido: ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);

            setTimeout(() => {
                window.location.replace('/home');
            }, 1500);
        }
    };

    const elementOptions = {
        style: {
            base: {
                fontSize: '18px',
                '::placeholder': {
                    color: '#a0a0a0',
                },
            },
        },
    };

    return (
        <Form onSubmit={handleSubmit} className="checkout-form">
            <Form.Group className="mb-3 mt-2 text-start">
                <Form.Label className='text-start ms-1'>Nombre:</Form.Label>
                <Form.Control
                    className='card-element-wrapper'
                    type="text"
                    placeholder="Ingrese su nombre"
                    value={gojimxName}
                    onChange={(e) => setGojimxName(e.target.value)}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3 mt-4 text-start">
                <Form.Label className='text-start ms-1'>Número de Tarjeta:</Form.Label>
                <CardNumberElement className="card-element-wrapper" options={elementOptions} />
            </Form.Group>
            <Form.Group className="mb-3 text-start">
                <Form.Label className='text-start ms-1'>Fecha de Expiración:</Form.Label>
                <CardExpiryElement className="card-element-wrapper" options={elementOptions} />
            </Form.Group>
            <Form.Group className="mb-3 text-start">
                <Form.Label className='text-start ms-1'>CVC:</Form.Label>
                <CardCvcElement className="card-element-wrapper" options={elementOptions} />
            </Form.Group>
            <Button type="submit" variant="dark" disabled={!stripe || processing || succeeded} className="w-100">
                {processing ? <Spinner animation="border" size="sm" /> : <span><i className="bi bi-lock-fill me-2"></i>Pagar Ahora</span>}
            </Button>
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {succeeded && <Alert variant="success" className="mt-3">¡Transacción completada!</Alert>}
        </Form>
    );
};

const PaymentCustom: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Use location to extract URL parameters

    const payment_type = localStorage.getItem('gojimx_payment_type');
    const business_id = localStorage.getItem('gojimx_businessId');

    const [showModal, setShowModal] = useState(true);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    // Extract 'amount' and 'renataPoints' from the URL parameters
    const searchParams = new URLSearchParams(location.search);
    const amount = searchParams.get('amount') || '0'; // Default to '0' if not present
    const renataPoints = searchParams.get('renataPoints') || '0'; // Default to '0' if not present

    const formatNumberWithCommas = (number: number) => {
        return new Intl.NumberFormat().format(number);
    };

    useEffect(() => {
        const createPaymentIntent = async () => {
            try {
                const token = localStorage.getItem('gojimx_token'); // asumiendo que el token está almacenado en local storage
                const response = await axios.post(
                    `${config.backendURL}/create_payment_intent`,
                    { business_id: localStorage.getItem('gojimx_businessId'), ammount_mxn: parseFloat(amount) }, // Use the extracted amount
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setClientSecret(response.data.clientSecret);
            } catch (error) {
                console.error('Error creando la intención de pago:', error);
            }
        };

        createPaymentIntent();
    }, [business_id, amount]);

    return (
        <Elements stripe={stripePromise}>
            <Modal show={showModal} size="lg" centered>
                <Modal.Body>
                    <div className='p-5 text-center'>
                        <div className="row mt-1">
                            <div className="col-6 text-start d-flex flex-column">
                                <h3>Resumen de compra</h3>
                                <h1 className='display-4'>${formatNumberWithCommas(Number(amount))} <span className='text-secondary' style={{ fontSize: '0.75em' }}>mxn</span></h1>
                                <span>Incluye:</span>
                                <h4 className='mt-2'><span className='fw-bold'><i className='bi bi-x-diamond-fill text-primary me-2'></i>{formatNumberWithCommas(Number(renataPoints))}</span> <span className='text-secondary'>Puntos renata</span></h4>
                                <p className="mt-auto">
                                    Al realizar esta compra, aceptas nuestros <a className='text-primary' href="https://goji.mx/terms">términos de servicio</a> y <a className='text-primary' href="https://goji.mx/privacy">política de privacidad</a>.
                                </p>
                            </div>
                            <div className="col-6 border-start text-start">
                                <div className="row justify-content-end">
                                    <div className="col-11">
                                        <h3>Detalles de la Tarjeta</h3>
                                        {clientSecret && <CheckoutForm clientSecret={clientSecret} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100">
                        <div className="col-6">
                            <img className="w-50" src={PoweredByStripe} alt="Powered By Stripe" style={{ opacity: 0.5 }} />
                        </div>
                        <div className="col-6 text-end">
                            <Button className="text-danger text-start" variant="light" onClick={handleLogout}>Cerrar Sesión</Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </Elements>
    );
};

export default PaymentCustom;