import React, { useEffect, useState } from 'react';
import { getWeekAppointments, decodeUnicode } from './functions/functions'; 
import { utcToZonedTime } from 'date-fns-tz';

interface WeekProps {
  current_date: Date;
}

interface Appointment {
  _id: string;
  userid: string;
  date: string;
  time: string;
  fullName: string;
  reason: string;
  business_id: string;
  expired: boolean;
  tag: string;
}

function WeekView({ current_date }: WeekProps) {

  const [surrounding_dates, setSurroundingDates] = useState<Date[]>([]);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [dates_html, setDatesHtml] = useState<string[]>([]);

  useEffect(() => {
    getSurroundingDates(current_date);
  }, [current_date]);

  // Function to determine the current appointment based on current time
  const setAppointmentStatus = (pAppointments: any) => {
    const mexicoCityTimeZone = 'America/Mexico_City';
    const nowUtc = new Date();
    const nowInMexicoCity = utcToZonedTime(nowUtc, mexicoCityTimeZone);
  
    let updatedAppointments = pAppointments.map((appointment: { date: string; time: string; }) => {
      const this_appointmentTime = new Date(appointment.date + ' ' + appointment.time);
      const isExpired = this_appointmentTime < nowInMexicoCity;
      return {
        ...appointment,
        expired: isExpired
      };
    });

    setAppointments(updatedAppointments);
    console.log(appointments);
  };

  function Year_Month_Day_String(pDate: Date) {
    const year = pDate.getFullYear();
    const month = (pDate.getMonth() + 1).toString().padStart(2, '0');
    const day = pDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const getSurroundingDates = (date: Date) => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
    const newDates = [
        new Date(date.getTime() - 2 * oneDay),
        new Date(date.getTime() - oneDay),
        new Date(date),
        new Date(date.getTime() + oneDay),
        new Date(date.getTime() + 2 * oneDay)
    ];
    setSurroundingDates(newDates);

    const newDatesHtml = newDates.map(d => {
        let dayName = d.toLocaleDateString('es-MX', { weekday: 'short' });
        dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
        const dayNumber = d.getDate();
        return `${dayName} <span class='text-dark fw-bold'>${dayNumber.toString().padStart(2, '0')}</span>`;
    });
    
    setDatesHtml(newDatesHtml);

    fetchAppointmentsForDates(); // Call without argument

  }

  const fetchAppointmentsForDates = async () => {
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    if (!gojimx_businessId) {
      console.error('Business ID not found in localStorage');
      return;
    }
    const businessId = String(gojimx_businessId);
    const formattedDate = Year_Month_Day_String(current_date);

    try {
      // Fetch week appointments using the getWeekAppointments function
      const appointments = await getWeekAppointments(formattedDate, businessId);
      
      const sortedAppointments = appointments.sort((a: { time: string; }, b: { time: any; }) => {
        return a.time.localeCompare(b.time);
      });

      setAppointmentStatus(sortedAppointments); // set appointments with their status

    } catch (error) {
      console.error('Error fetching week appointments:', error);
    }
  };

  function extractEmoji(tag: string): string | null {
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{FE00}-\u{FE0F}\u{1F018}-\u{1F270}\u{1F260}-\u{1F260}\u{1F240}-\u{1F251}\u{1F201}-\u{1F210}\u{1F004}-\u{1F0CF}]/gu;
    const emoji = tag.match(emojiRegex);
    return emoji ? emoji[0] : null;
  }

  const getTagColorClass = (tag: string) => {
    switch (extractEmoji(tag)) {
      case '🔴':
        return 'border-start border-danger';
      case '🟠':
        return 'border-orange';
      case '🟡':
        return 'border-start border-warning';
      case '🟢':
        return 'border-start border-success';
      case '🔵':
        return 'border-start border-primary';
      case '🟣':
        return 'border-start border-info';
      default:
        return 'border-start';
    }
  };

  const renderAppointmentsForDate = (date: string) => {
    return appointments
      .filter(appointment => appointment.date === date)
      .map(appointment => (
        <div 
          key={appointment._id} 
          className={`border-5 ${appointment.expired ? 'border-start border-secondary' : getTagColorClass(appointment.tag)} bg-light rounded p-3 mb-3 animate__animated animate__fadeIn clickable`}
        >
          <h4 className='w-100 text-secondary fw-bold my-0'>{appointment.time}</h4>
          <p className='w-100 text-dark fw-bold my-0'>{decodeUnicode(appointment.fullName)}</p>
        </div>
      ));
  };
  
  
  // Rendering the dates
  const renderedDates = dates_html.map((dateHtml, index) => {
    const date = surrounding_dates[index];
    const formattedDate = Year_Month_Day_String(date);

    return (
      <div key={index} className='col w-20 py-3'>
        <h4 className='w-100 text-secondary p-3 mb-3 text-center border-bottom border-2' dangerouslySetInnerHTML={{__html: dateHtml}}></h4>
        {renderAppointmentsForDate(formattedDate)}
      </div>
    );
  });

  return (
    <div className='row px-0'>
      {renderedDates}
    </div>
  );
}

export default WeekView;
