import React, { useState, ChangeEvent } from 'react';
import Logo from './img/renata-logo.svg';
import loadingGif from './img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from './Components/Modals/ConfirmationModal';
import config from './config/config';

function ChangePassword() {
    const gojimx_phone = localStorage.getItem('gojimx_phone');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('La contraseña ha sido actualizada correctamente');
    const [confirmModalType, setConfirmModalType] = useState('✅')
    const [success, setSuccess] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const handleCloseConfirmationModal = () => {
        if (success) {
            setShowConfirmationModal(false);
            navigate('/settings');


        } else {
            setShowConfirmationModal(false);

        }
      };

    const handleCurrentPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(event.target.value);
        if (event.target.value.trim()) {
            setCurrentPasswordError(false);
        }
    };

    const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
        if (event.target.value.trim()) {
            setNewPasswordError(false);
        }
    };

    const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
        if (event.target.value.trim()) {
            setConfirmPasswordError(false);
        }
    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let isValid = true;
        setIsLoading(true);
        if (!gojimx_phone) {
            setConfirmationModalText('Error interno, por favor, cierre y vuelva a iniciar sesión');
            setConfirmModalType('❌')
            setShowConfirmationModal(true);
        }
        if (!currentPassword.trim()) {
            setCurrentPasswordError(true);
            isValid = false;
        }

        if (!newPassword.trim()) {
            setNewPasswordError(true);
            isValid = false;
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordError(true);
            isValid = false;
        }

        if (!isValid) {
            setIsLoading(false);
            return;
        }
        axios.post(`${config.backendURL}/change-password`, {
            phoneNumber: gojimx_phone,
            currentPassword: currentPassword,
            confirmPassword: confirmPassword,
            newPassword: newPassword
        })
            .then(response => {
                setIsLoading(false);
                setConfirmationModalText('La contraseña ha sido actualizada correctamente.');
                setConfirmModalType('✅')
                setShowConfirmationModal(true);
                setSuccess(true);
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setLoginError('Credenciales incorrectas, por favor verifica tu contraseña.');
                    } else if (error.response.status === 500) {
                        setLoginError('Error interno del servidor, por favor inténtalo nuevamente más tarde.');
                    }
                } else {
                    setLoginError('Se produjo un error, por favor verifica tu conexión a internet o inténtalo nuevamente más tarde.');
                }

                setIsLoading(false);
            });
    };

    return (
        <div className='flex-column align-items-center py-3 px-5'>
            <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
                    <img className="w-50 mt-5" src={Logo} alt="Renata Logo" />
                </div>
            </div>

            <div className='row justify-content-center'>
                <div className="col-sm-12 col-md-4 col-lg-3 p-login">
                    <h1 className='fw-bolder text-dark text-center mb-4 animate__animated animate__fadeInUp'>Cambiar contraseña</h1>
                    <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input
                                type="password"
                                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${currentPasswordError ? 'is-invalid' : ''}`}
                                placeholder='Contraseña actual'
                                onChange={handleCurrentPasswordChange}
                                autoComplete="off"
                            />
                            {currentPasswordError && <div className="invalid-feedback">Por favor, introduce tu contraseña actual.</div>}
                        </div>
                        <div className="mb-3">
                            <input
                                type="password"
                                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${newPasswordError ? 'is-invalid' : ''}`}
                                placeholder='Nueva contraseña'
                                onChange={handleNewPasswordChange}
                                autoComplete="off"
                            />
                            {newPasswordError && <div className="invalid-feedback">Por favor, introduce tu nueva contraseña.</div>}
                        </div>
                        <div className="mb-3">
                            <input
                                type="password"
                                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${confirmPasswordError ? 'is-invalid' : ''}`}
                                placeholder='Confirma tu nueva contraseña'
                                onChange={handleConfirmPasswordChange}
                                autoComplete="off"
                            />
                            {confirmPasswordError && <div className="invalid-feedback">Las contraseñas no coinciden.</div>}
                        </div>
                        <button type="submit" className="btn btn-dark w-100" disabled={isLoading}>
                            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Cambiar contraseña'}
                        </button>
                        {loginError && <div className="alert alert-danger mt-3">{loginError}</div>}

                    </form>
                    <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCloseConfirmationModal}
        text={confirmationModalText}
        type={confirmModalType}
        isLoading={isLoading}
        titleText='Configuración'
      />
                </div>
            </div>

        </div>
    );
}

export default ChangePassword;