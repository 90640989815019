import React, { useState } from 'react';
import ChannelCard from '../Cards/ChannelCard';
import Logo from '../../img/renata-settings.png';
import WhatsAppLogo from '../../img/WhatsApp_Logo.png';
import InstagramLogo from '../../img/Instagram_Logo.png';
import LinkedinLogo from '../../img/Linkedin_Logo.png';
import AddChannelModal from '../Modals/AddChannelModal';
import XLogo from '../../img/X_Logo.png';
import MessengerLogo from '../../img/Messenger_Logo.png';
import ChannelOptionsModal from '../Modals/ChannelOptionsModal';

interface Assistant {
    assistant_id: string;
    name: string;
    description: string;
    intelligenceLevel: string;
    responseLength: string;
    workingHoursStart: number;
    workingHoursEnd: number;
    responseSpeed: string;
    instructions: string;
    active: boolean;
}

interface Option {
    option_name: string;
    description: string;
    icon: string;
}

interface Channel {
    channel_name: string;
    active: boolean;
    username: string;
    main_number: boolean,
    baileys_port: string,
    baileys_status: boolean,
}

interface ChannelProps {
    mainAssistant: Assistant | null;
    channels: Channel[];
    fetchBusiness:  () => void
}

const Channel: React.FC<ChannelProps> = ({ mainAssistant, channels, fetchBusiness }) => {
    const [showAddChannelModal, setShowAddChannelModal] = useState(false);
    const [showChannelOptionsModal, setShowChannelOptionsModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState<Channel>();

    const getChannelLogoAndBackground = (channelName: string) => {
        switch (channelName) {
            case 'WhatsApp':
                return { logo: WhatsAppLogo, background: '#d1ebd8' };
            case 'Instagram':
                return { logo: InstagramLogo, background: '#f9dbc6' };
            case 'LinkedIn':
                return { logo: LinkedinLogo, background: '#cbdbef' };
            case 'Messenger':
                return { logo: MessengerLogo, background: '#c9daed' };
            case 'X':
                return { logo: XLogo, background: '#4d4d4d' };
            default:
                return { logo: '', background: '#ffffff' };
        }
    };

    const handleCardClick = (channel: Channel) => {
        setSelectedChannel(channel);
        handleChannelOptionsClick();
    };

    const handleAddChannelClick = () => {
        setShowAddChannelModal(true);
    };

    const handleModalAddChannelClose = () => {
        setShowAddChannelModal(false);
    };

    const handleChannelOptionsClick = () => {
        setShowChannelOptionsModal(true);
    };

    const handleChannelOptionsClose = () => {
        setShowChannelOptionsModal(false);
    };

    return (
        <div className="container px-3 animate__animated animate__fadeIn mb-3">
            <div className="row">
                <h2 className="fw-bold text-dark my-0">Mis canales</h2>
                <p className="text-dark mt-0 mb-3">Conecta los canales en los que Renata contestará tus mensajes</p>
                <div className="col-md-4">
                    <div className="channels-list position-relative">
                        {channels.map((channel, index) => {
                            const { logo, background } = getChannelLogoAndBackground(channel.channel_name);
                            return (
                                <ChannelCard
                                    key={index}
                                    icon=""
                                    text={channel.username}
                                    lineClass={`line${index + 1}`}
                                    background={background}
                                    logo={logo}
                                    onClick={() => handleCardClick(channel)}
                                    paddingClass='p-4'
                                    main_number={channel.main_number}
                                    baileys_status={channel.baileys_status}
                                    is_add_button={false}

                                />
                            );
                        })}
                        { channels.length < 3 &&
                        <ChannelCard
                            icon="➕"
                            text=""
                            lineClass={`line${channels.length + 1}`}
                            background="#e7e7e7"
                            logo=""
                            onClick={handleAddChannelClick}
                            paddingClass='p-1'
                            main_number={false}
                            baileys_status={false}
                            is_add_button={true}
                        
                        />
}
                    </div>
                </div>
                <div className="col-md-1 d-flex align-items-center justify-content-center">
                    <div className="vertical-line position-relative"></div>
                </div>
                <div className="col d-flex align-items-center">
                    <div className="btn bg-light p-3 mb-3 w-100 d-flex align-items-center rounded-5">
                        <img style={{ width: '20%' }} className="me-2" src={Logo} alt="Renata Logo" />
                        <div className="text-start">
                            <h4>{mainAssistant ? mainAssistant.name : 'Renata'}</h4>
                            <small>{mainAssistant ? mainAssistant.description : ''}</small>
                        </div>
                    </div>
                </div>
            </div>
            <AddChannelModal show={showAddChannelModal} onHide={handleModalAddChannelClose} isLoading={isLoading} fetchBusiness={fetchBusiness}/>
            <ChannelOptionsModal show={showChannelOptionsModal} onHide={handleChannelOptionsClose} isLoading={false} fetchBusiness={fetchBusiness} channel={selectedChannel}></ChannelOptionsModal>
        </div>
    );
};

export default Channel;