import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

/*interface CalendarFormProps {
  data: {
    habilidadName: string;
    habilidadDescription: string;
    tituloEvento: string,
    fechaInicio: string,
    horaInicio: string,
    fechaFin: string,
    horaFin: string,
    eventoDescription: string,
    participantes: string,
    ubicacion:string,
  };
  onChange: (data: CalendarFormProps['data']) => void;
}*/
interface CalendarFormProps {
  data: {
    habilidadName: string;
    habilidadDescription: string;
    date: string,
    time: string,
    fullName: string,
    reason: string,

  };
  onChange: (data: CalendarFormProps['data']) => void;
}

const CalendarForm: React.FC<CalendarFormProps> = ({ data, onChange }) => {
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    onChange(formData); // Llamar onChange cada vez que formData cambie
  }, [formData]);

  useEffect(() => {
    setFormData(data); // Sincroniza formData con los datos recibidos cuando cambian
  }, [data]);
/*
  return (
    <Form className="p-3">
      <Form.Group controlId="habilidadName" className="mb-4">
        <Form.Label className="fw-bold">Identificador de la habilidad</Form.Label>
        <Form.Control
          type="text"
          value={formData.habilidadName}
          onChange={(e) => setFormData({ ...formData, habilidadName: e.target.value })}
          placeholder="Introduce el nombre de la habilidad, recuerda que debe ser un nombre unico"
        />
      </Form.Group>

      <Form.Group controlId="habilidadDescription" className="mb-4">
        <Form.Label className="fw-bold">Descripción de la habilidad</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={formData.habilidadDescription}
          onChange={(e) => setFormData({ ...formData, habilidadDescription: e.target.value })}
          placeholder="Describe la habilidad. cuando y como deseas que sea utilizada por el asistente"
        />
      </Form.Group>

      <Form.Group controlId="tituloEvento" className="mb-4">
        <Form.Label className="fw-bold">Nombre del evento </Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{titulo}}'}</code> para dejar que renata autocomplete
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.tituloEvento}
          onChange={(e) => setFormData({ ...formData, tituloEvento: e.target.value })}
          placeholder="{{titulo}}"
        />
      </Form.Group>

      <Form.Group controlId="fechaInicio" className="mb-4">
        <Form.Label className="fw-bold">Fecha de inicio del evento </Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{fecha}}'}</code> para dejar que renata autocomplete o formato YYYY-MM-DD
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.fechaInicio}
          onChange={(e) => setFormData({ ...formData, fechaInicio: e.target.value })}
          placeholder="{{fecha}}"
        />
      </Form.Group>

      <Form.Group controlId="horaInicio" className="mb-4">
        <Form.Label className="fw-bold">Hora de inicio del evento </Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{hora}}'}</code> para dejar que renata autocomplete o formato de 24 horas como ejemplo: 18:30
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.horaInicio}
          onChange={(e) => setFormData({ ...formData, horaInicio: e.target.value })}
          placeholder="{{hora}}"
        />
      </Form.Group>

      <Form.Group controlId="fechaFin" className="mb-4">
        <Form.Label className="fw-bold">Fecha de finalización del evento </Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{fecha}}'}</code> para dejar que renata autocomplete o formato YYYY-MM-DD
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.fechaFin}
          onChange={(e) => setFormData({ ...formData, fechaFin: e.target.value })}
          placeholder="{{fecha}}"
        />
      </Form.Group>

      <Form.Group controlId="horaFin" className="mb-4">
        <Form.Label className="fw-bold">Hora de finalización del evento </Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{hora}}'}</code> para dejar que renata autocomplete o formato de 24 horas como ejemplo: 18:30
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.horaFin}
          onChange={(e) => setFormData({ ...formData, horaFin: e.target.value })}
          placeholder="{{hora}}"
        />
      </Form.Group>

      <Form.Group controlId="eventoDescription" className="mb-4">
        <Form.Label className="fw-bold">Descripción del evento en el calendario </Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{descripcion}}'}</code> para dejar que renata autocomplete 
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.eventoDescription}
          onChange={(e) => setFormData({ ...formData, eventoDescription: e.target.value })}
          placeholder="{{descripcion}}"
        />
      </Form.Group>

      <Form.Group controlId="participantes" className="mb-4">
        <Form.Label className="fw-bold">Participantes en el evento </Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{participantes}}'}</code> para dejar que renata autocomplete 
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.participantes}
          onChange={(e) => setFormData({ ...formData, participantes: e.target.value })}
          placeholder="{{participantes}}"
        />
      </Form.Group>

      <Form.Group controlId="ubicacion" className="mb-4">
        <Form.Label className="fw-bold">ubicacion del evento </Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{ubicacion}}'}</code> para dejar que renata autocomplete 
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.ubicacion}
          onChange={(e) => setFormData({ ...formData, ubicacion: e.target.value })}
          placeholder="{{ubicacion}}"
        />
      </Form.Group>

    </Form>
  );
};
*/ 
//change to use the old values:
return (
  <Form className="p-3">
    <Form.Group controlId="habilidadName" className="mb-4">
      <Form.Label className="fw-bold">Identificador de la habilidad</Form.Label>
      <Form.Control
        type="text"
        value={formData.habilidadName}
        onChange={(e) => setFormData({ ...formData, habilidadName: e.target.value })}
        placeholder="Introduce el nombre de la habilidad, recuerda que debe ser un nombre unico"
      />
    </Form.Group>

    <Form.Group controlId="habilidadDescription" className="mb-4">
      <Form.Label className="fw-bold">Descripción de la habilidad</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        value={formData.habilidadDescription}
        onChange={(e) => setFormData({ ...formData, habilidadDescription: e.target.value })}
        placeholder="Describe la habilidad. cuando y como deseas que sea utilizada por el asistente"
      />
    </Form.Group>

    <Form.Group controlId="date" className="mb-4">
      <Form.Label className="fw-bold">Fecha </Form.Label>
      <Form.Label className="mt-0 mb-2">
          Utiliza la variable <code>{'{{Fecha}}'}</code> para dejar que renata autocomplete o formato YYYY-MM-DD
      </Form.Label>
      <Form.Control
        type="text"
        value={formData.date}
        onChange={(e) => setFormData({ ...formData, date: e.target.value })}
        placeholder="{{Fecha}}"
      />
    </Form.Group>

    <Form.Group controlId="time" className="mb-4">
      <Form.Label className="fw-bold">Hora del evento </Form.Label>
      <Form.Label className="mt-0 mb-2">
          Utiliza la variable <code>{'{{Hora}}'}</code> para dejar que renata autocomplete o formato de 24 horas como ejemplo: 18:30
      </Form.Label>
      <Form.Control
        type="text"
        value={formData.time}
        onChange={(e) => setFormData({ ...formData, time: e.target.value })}
        placeholder="{{Hora}}"
      />
    </Form.Group>

    <Form.Group controlId="fullName" className="mb-4">
      <Form.Label className="fw-bold">Nombre del evento </Form.Label>
      <Form.Label className="mt-0 mb-2">
          Utiliza la variable <code>{'{{Nombre}}'}</code> para dejar que renata autocomplete 
      </Form.Label>
      <Form.Control
        type="text"
        value={formData.fullName}
        onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
        placeholder="{{Nombre}}"
      />
    </Form.Group>

    <Form.Group controlId="reason" className="mb-4">
      <Form.Label className="fw-bold">Motivo del evento </Form.Label>
      <Form.Label className="mt-0 mb-2">
          Utiliza la variable <code>{'{{Motivo}}'}</code> para dejar que renata autocomplete 
      </Form.Label>
      <Form.Control
        type="text"
        value={formData.reason}
        onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
        placeholder="{{Motivo}}"
      />
    </Form.Group>

  </Form>
);
};

export default CalendarForm;