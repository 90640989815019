import React from 'react';

interface ChannelCardProps {
    icon: string;
    text: string;
    lineClass: string;
    background: string;
    logo: string;
    onClick: () => void;
    paddingClass: string; // Agregado paddingClass
    main_number: boolean,
    baileys_status: boolean,
    is_add_button: boolean
}

const ChannelCard: React.FC<ChannelCardProps> = ({ icon, text, lineClass, background, logo, onClick, paddingClass, main_number, baileys_status, is_add_button }) => (
    <div className="d-flex align-items-center mb-4 position-relative" onClick={onClick}>
        <div className={`card rounded-5 flex-grow-1 d-flex align-items-center ${paddingClass}`} style={{ background }}>
            {icon.length > 0 ? (
                <h1><i className="bi bi-plus-lg"></i></h1>
            ) : (
                <img src={logo} alt="icon" className="" style={{ width: '48px', height: '48px' }} />
            )}
            <div className="text-container">{text}</div>
            {!is_add_button ? (
                <>
                    {main_number ? <div className="text-container">Número principal</div>
                        : ''}
                    {/* {baileys_status ? <div className="text-container">Conectado</div>
                        : <div className="text-container">Desconectado</div>} */}

                </>
            ) : ''}


        </div>
        <div className={`line ${lineClass}`}></div>
        {
            !is_add_button &&
        
        <div className={`status-dot ${baileys_status ? 'connected' : 'disconnected'} mt-2 mx-3`}></div>
        }
    </div>
);

export default ChannelCard;