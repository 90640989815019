import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import GmailIcon from './img/gmail.png';
import WebhookIcon from './img/webhook.png';
import CalendarIcon from './img/renata_calendar.png'; //cambiar la imagen a la de calendar
import WebHookForm from './Components/SkillsForm/WebHookForm';
import SendEmailForm from './Components/SkillsForm/SendEmailForm';
import CalendarForm from './Components/SkillsForm/CalendarForm';
import ConfirmationModal from './Components/Modals/ConfirmationModal'; // Import your confirmation modal component
import axios, { AxiosError } from 'axios';
import config from './config/config';
import loadingGif from './img/loading.gif';
import { fetchBusinessStatus, getSkills } from './functions/functions';
import EditWebhookSkillModal from './Components/SkillsForm/EditWebHookSkillModal';
import EditWSendEmailSkillModal from './Components/SkillsForm/EditSendEmailSkillModal';
import EditWCalendarSkillModal from './Components/SkillsForm/EditCalendarSkillModal';
import DeleteConfirmationModal from './Components/Modals/DeleteConfirmationModal';

interface Skill {
    skill_type: string;
    skill_name: string;
    skill_description: string;
    skill_id: string;
    skill_properties: {
        [key: string]: any;
    };
}

interface MySkillsProps {
    fetchBusiness: () => void
}

type SkillType = 'send_email' | 'skill_webhook' | 'calendar' | null;

const MySkills: React.FC<MySkillsProps> = ({ fetchBusiness }) => {
    const [showModal, setShowModal] = useState(false);
    const [activeSkill, setActiveSkill] = useState<SkillType>(null);
    const [previousActiveSkill, setPreviousActiveSkill] = useState<SkillType>(null);

    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const gojimx_token = localStorage.getItem('gojimx_token');
    const [showEditWebhookModal, setShowEditWebhookModal] = useState(false);
    const [showEditSendEmailModal, setShowEditSendEmailModal] = useState(false);
    const [showEditCalendarModal, setShowEditCalendarModal] = useState(false);

    const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);

    const [showConfirmModal, setShowConfirmModal] = useState(false);


    const [webhookData, setWebhookData] = useState({
        habilidadName: '',
        habilidadDescription: '',
        method: 'GET',
        url: '',
        parametros: [{ value: '', type: 'string', description: '' }],
        jsonData: {},
        authorizationType: 'Bearer',
        authorizationValue: '',
    });

    const [sendEmailData, setSendEmailData] = useState({
        habilidadName: '',
        habilidadDescription: '',
        destination: '{{destination}}',
        subject: '{{subject}}',
        body: '{{body}}',
    });

    /*const [calendarData, setCalendarData] = useState({
        habilidadName: '',
        habilidadDescription: '',
        tituloEvento: '{{titulo}}',
        fechaInicio: '{{fecha}}',
        horaInicio: '{{hora}}',
        fechaFin: '{{fecha}}',
        horaFin: '{{hora}}',
        eventoDescription: '{{descripcion}}',
        participantes: '{{participantes}}',
        ubicacion:'{{ubicacion}}',
    });*/
    const [calendarData, setCalendarData] = useState({
        habilidadName: '',
        habilidadDescription: '',
        date: '{{Fecha}}',
        time: '{{Hora}}',
        fullName: '{{Nombre}}',
        reason: '{{Motivo}}',

    });

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('');
    const [confirmModalType, setConfirmModalType] = useState<'✅' | '⚠️' | '❌'>('✅');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEditSkill, setIsLoadingEditSkill] = useState(false);

    const [modalMessage, setModalMessage] = useState('');
    const [showSkills, setShowSkills] = useState<Skill[]>([]);
    const [refresh, setRefresh] = useState(false);
    const [deleteSkillType, setDeteleSkillType] = useState('');

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleEditSkill = (skill: Skill) => {
        setSelectedSkill(skill);
        if (skill.skill_type === 'skill_webhook') {
            setShowEditWebhookModal(true);
        } else if (skill.skill_type === 'send_email') {
            setShowEditSendEmailModal(true);
        } else if (skill.skill_type === 'calendar') {
            setShowEditCalendarModal(true);
        }
    };

    const handleSaveEditedWebhookSkill = async (updatedData: any) => {
        try {
            if (
                !updatedData.skill_name.trim() ||
                !updatedData.skill_description.trim() ||
                !updatedData.skill_properties.url.trim() ||
                !updatedData.skill_properties.authorizationType.trim() ||
                (updatedData.skill_properties.authorizationType !== 'Ninguno' && !updatedData.skill_properties.authorizationValue.trim()) // Skip authorizationValue check if type is None
            ) {
                setConfirmModalType('❌');
                setConfirmationModalText('Campos vacíos');
                setModalMessage('Por favor, completa todos los campos obligatorios antes de guardar los cambios.');
                setShowConfirmationModal(true);
                return;
            }
            setIsLoadingEditSkill(true);
            const businessId = localStorage.getItem('gojimx_businessId');
            const object = { business_id: businessId, skill_id: updatedData.skill_id, skill_name: updatedData.skill_name, skill_type: updatedData.skill_type, skill_properties: updatedData };
            const token = localStorage.getItem('gojimx_token');
            //console.log(JSON.stringify(object, null, 2));
            const security = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            const response = await axios.patch(`${config.backendURL}/editSkill`, object, security);
            setConfirmModalType('✅');
            setConfirmationModalText('Habilidad editada con éxito');
            setModalMessage('La habilidad ha sido editada correctamente.');
            setShowConfirmationModal(true);
            setRefresh(!refresh);
            handleCloseSkillModal();
            // Refresh your skills list here
        } catch (error: any) {
            setConfirmModalType('❌');
            setConfirmationModalText('Error');
            if (error.response.status === 404) {
                setModalMessage('Error al encontrar la habilidad en el negocio.');

            } else if (error.response.status === 400) {
                setModalMessage(`Valores incorrectos de entrada`);
            } else if (error.response.status === 405) {
                setModalMessage(`Ya existe una habilidad con este nombre.`);
            } else {
                setModalMessage('Hubo un error al editar la habilidad. Por favor, inténtelo de nuevo.');

            }
            setShowConfirmationModal(true);
            handleCloseSkillModal();
        } finally {
            fetchBusiness();
            setIsLoadingEditSkill(false);
        }
    };

    const handleSaveEditedSendEmailSkill = async (updatedData: any) => {
        //const {business_id, skill_id, skill_name, skill_type, skill_properties} skill_properties debe tener toda la infor de skills
        try {
            if (
                !updatedData.skill_name.trim() ||
                !updatedData.skill_description.trim() ||
                !updatedData.skill_properties.body.trim() ||
                !updatedData.skill_properties.destination.trim() ||
                !updatedData.skill_properties.subject.trim()  ) {
                setConfirmModalType('❌');
                setConfirmationModalText('Campos vacíos');
                setModalMessage('Por favor, completa todos los campos obligatorios antes de guardar los cambios.');
                setShowConfirmationModal(true);
                return;
            }
            setIsLoadingEditSkill(true);
            const businessId = localStorage.getItem('gojimx_businessId');
            const object = { business_id: businessId, skill_id: updatedData.skill_id, skill_name: updatedData.skill_name, skill_type: updatedData.skill_type, skill_properties: updatedData };
            const token = localStorage.getItem('gojimx_token');
            //console.log(JSON.stringify(object, null, 2));
            const security = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            const response = await axios.patch(`${config.backendURL}/editSkill`, object, security);
            setConfirmModalType('✅');
            setConfirmationModalText('Habilidad editada con éxito');
            setModalMessage('La habilidad ha sido editada correctamente.');
            setShowConfirmationModal(true);
            setRefresh(!refresh);
            handleCloseSkillModal();
            // Refresh your skills list here
        } catch (error: any) {
            setConfirmModalType('❌');
            setConfirmationModalText('Error');
            if (error.response.status === 404) {
                setModalMessage('Error al encontrar la habilidad en el negocio.');

            } else if (error.response.status === 400) {
                setModalMessage(`Valores incorrectos de entrada`);
            } else if (error.response.status === 405) {
                setModalMessage(`Ya existe una habilidad con este nombre.`);
            } else {
                setModalMessage('Hubo un error al editar la habilidad. Por favor, inténtelo de nuevo.');

            }
            setShowConfirmationModal(true);
            handleCloseSkillModal();
        } finally {
            fetchBusiness();
            setIsLoadingEditSkill(false);
        }
    };
    const handleSaveEditedCalendarSkill = async (updatedData: any) => {
        try {
            if (
                !updatedData.skill_name.trim() ||
                !updatedData.skill_description.trim() ||
                !updatedData.skill_properties.date.trim() ||
                !updatedData.skill_properties.time.trim() ||
                !updatedData.skill_properties.fullName.trim() ||
                !updatedData.skill_properties.reason.trim()  ) {
                setConfirmModalType('❌');
                setConfirmationModalText('Campos vacíos');
                setModalMessage('Por favor, completa todos los campos obligatorios antes de guardar los cambios.');
                setShowConfirmationModal(true);
                return;
            }
            setIsLoadingEditSkill(true);
            const businessId = localStorage.getItem('gojimx_businessId');
            const object = { business_id: businessId, skill_id: updatedData.skill_id, skill_name: updatedData.skill_name, skill_type: updatedData.skill_type, skill_properties: updatedData };
            const token = localStorage.getItem('gojimx_token');
            const security = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            const response = await axios.patch(`${config.backendURL}/editSkill`, object, security);
            setConfirmModalType('✅');
            setConfirmationModalText('Habilidad editada con éxito');
            setModalMessage('La habilidad ha sido editada correctamente.');
            setShowConfirmationModal(true);
            setRefresh(!refresh);
            handleCloseSkillModal();
            // Refresh your skills list here
        } catch (error: any) {
            setConfirmModalType('❌');
            setConfirmationModalText('Error');
            if (error.response.status === 404) {
                setModalMessage('Error al encontrar la habilidad en el negocio.');

            } else if (error.response.status === 400) {
                setModalMessage(`Valores incorrectos de entrada`);
            } else if (error.response.status === 405) {
                setModalMessage(`Ya existe una habilidad con este nombre.`);
            } else {
                setModalMessage('Hubo un error al editar la habilidad. Por favor, inténtelo de nuevo.');

            }
            setShowConfirmationModal(true);
            handleCloseSkillModal();
        } finally {
            fetchBusiness();
            setIsLoadingEditSkill(false);
        }
    };

    const handleDeleteCalendarSkill = async (updatedData: any) => {
        try {
            setIsLoading(true);
            const businessId = localStorage.getItem('gojimx_businessId');
            const object = { business_id: businessId, skill_name: updatedData.skill_name };
            const token = localStorage.getItem('gojimx_token');
            const security = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            const response = await axios.post(`${config.backendURL}/deleteSkill`, object, security);
            setConfirmModalType('✅');
            setConfirmationModalText('Habilidad eliminada con éxito');
            setModalMessage('La habilidad ha sido eliminada correctamente.');
            setShowConfirmationModal(true);
            setRefresh(!refresh);
            handleCloseSkillModal();
        } catch (error: any) {
            setConfirmModalType('❌');
            setConfirmationModalText('Error');
            if (error.response.status === 404) {
                setModalMessage('Error al encontrar la habilidad en el negocio.');

            } else if (error.response.status === 400) {
                setModalMessage(`Valores incorrectos de entrada`);
            } else {
                setModalMessage('Hubo un error al eliminar la habilidad. Por favor, inténtelo de nuevo.');

            }
            setShowConfirmationModal(true);
            handleCloseSkillModal();
        } finally {
            fetchBusiness();
            setIsLoading(false);
        }
    };

    const handleDeleteSendEmailSkill = async (updatedData: any) => {
        try {
            setIsLoading(true);
            const businessId = localStorage.getItem('gojimx_businessId');
            const object = { business_id: businessId, skill_name: updatedData.skill_name };
            const token = localStorage.getItem('gojimx_token');
            const security = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            const response = await axios.post(`${config.backendURL}/deleteSkill`, object, security);
            setConfirmModalType('✅');
            setConfirmationModalText('Habilidad eliminada con éxito');
            setModalMessage('La habilidad ha sido eliminada correctamente.');

            setShowConfirmationModal(true);
            setRefresh(!refresh);
            handleCloseSkillModal();
        } catch (error: any) {
            setConfirmModalType('❌');
            setConfirmationModalText('Error');
            if (error.response.status === 404) {
                setModalMessage('Error al encontrar la habilidad en el negocio.');

            } else if (error.response.status === 400) {
                setModalMessage(`Valores incorrectos de entrada`);
            } else {
                setModalMessage('Hubo un error al eliminar la habilidad. Por favor, inténtelo de nuevo.');

            }
            setShowConfirmationModal(true);
            handleCloseSkillModal();
        } finally {
            fetchBusiness();
            setIsLoading(false);
        }
    };

    const handleDeleteWebhookSkill = async (updatedData: any) => {
        try {
            setIsLoading(true);
            const businessId = localStorage.getItem('gojimx_businessId');
            const object = { business_id: businessId, skill_name: updatedData.skill_name };
            const token = localStorage.getItem('gojimx_token');
            const security = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            const response = await axios.post(`${config.backendURL}/deleteSkill`, object, security);
            setConfirmModalType('✅');
            setConfirmationModalText('Habilidad eliminada con éxito');
            setModalMessage('La habilidad ha sido eliminada correctamente.');

            setShowConfirmationModal(true);
            setRefresh(!refresh);
            handleCloseSkillModal();
        } catch (error: any) {
            setConfirmModalType('❌');
            setConfirmationModalText('Error');
            if (error.response.status === 404) {
                setModalMessage('Error al encontrar la habilidad en el negocio.');

            } else if (error.response.status === 400) {
                setModalMessage(`Valores incorrectos de entrada`);
            } else {
                setModalMessage('Hubo un error al eliminar la habilidad. Por favor, inténtelo de nuevo.');

            }
            setShowConfirmationModal(true);
            handleCloseSkillModal();
        } finally {
            fetchBusiness();
            setIsLoading(false);
        }
    };

    const handleSelectSkill = (skill: SkillType) => {
        setActiveSkill(skill);
        setShowModal(false); // Close add skill modal
    };

    const handleCloseSkillModal = () => {
        setWebhookData({
            habilidadName: '',
            habilidadDescription: '',
            method: 'GET',
            url: '',
            parametros: [{ value: '', type: 'string', description: '' }],
            jsonData: {},
            authorizationType: 'Bearer',
            authorizationValue: '',
        })
        setSendEmailData({
            habilidadName: '',
            habilidadDescription: '',
            destination: '{{destination}}',
            subject: '{{subject}}',
            body: '{{body}}',
        });
        setCalendarData({
            habilidadName: '',
            habilidadDescription: '',
            date: '{{Fecha}}',
            time: '{{Hora}}',
            fullName: '{{Nombre}}',
            reason: '{{Motivo}}',
        });
        setActiveSkill(null);
        setPreviousActiveSkill(null);
    };

    const handleWebhookChange = (newData: typeof webhookData) => {
        setWebhookData(newData);
    };

    const handleSendEmailChange = (newData: typeof sendEmailData) => {
        setSendEmailData(newData);
    };

    const handleCalendarChange = (newData: typeof calendarData) => {
        setCalendarData(newData);
    };

    const handleSaveWebhook = async () => {
        // Check if any required field in webhookData is empty
        if (
            !webhookData.habilidadName.trim() ||
            !webhookData.habilidadDescription.trim() ||
            !webhookData.method.trim() ||
            !webhookData.url.trim() ||
            !webhookData.authorizationType.trim() ||
            (webhookData.authorizationType !== 'Ninguno' && !webhookData.authorizationValue.trim()) // Skip authorizationValue check if type is None
        ) {
            setConfirmModalType('❌');
            setConfirmationModalText('Campos vacíos');
            setModalMessage('Por favor, completa todos los campos obligatorios antes de guardar.');
            setPreviousActiveSkill(activeSkill);
            setActiveSkill(null);
            setShowConfirmationModal(true);
            return;
        }
        try {
            setIsLoading(true);
            const properties = {
                authorizationType: webhookData.authorizationType,
                authorizationValue: webhookData.authorizationType === 'None' ? '' : webhookData.authorizationValue,
                body: webhookData.jsonData,
                method: webhookData.method,
                parameters: webhookData.parametros,
                url: webhookData.url
            };
            const response = await axios.post(`${config.backendURL}/createSkill`, {
                business_id: gojimx_businessId,
                skill_name: webhookData.habilidadName,
                skill_type: activeSkill,
                skill_description: webhookData.habilidadDescription,
                skill_properties: properties
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${gojimx_token}`
                }
            });

            // Handle successful response
            setConfirmModalType('✅');
            setConfirmationModalText('Habilidad creada con éxito');
            setModalMessage('La habilidad ha sido creada y guardada correctamente.');
            setShowConfirmationModal(true);
            setPreviousActiveSkill(null);

            setWebhookData({
                habilidadName: '',
                habilidadDescription: '',
                method: 'GET',
                url: '',
                parametros: [{ value: '', type: 'string', description: '' }],
                jsonData: {},
                authorizationType: 'Bearer',
                authorizationValue: '',
            });
            setRefresh(!refresh); // to show the new skill in the web page
            handleCloseSkillModal();
        } catch (error: any) {
            // Handle error response
            setConfirmModalType('❌');
            setConfirmationModalText('Error');
            if (error.response.status === 400) {
                setModalMessage('Ya existe una habilidad con este nombre.');

            } else {
                setModalMessage('Hubo un error al crear la habilidad. Por favor, inténtelo de nuevo.');

            }
            setPreviousActiveSkill(activeSkill);
            setActiveSkill(null);
            setShowConfirmationModal(true);
        } finally {
            fetchBusiness();
            setIsLoading(false);
        }
    };

    const handleSaveSendEmail = async () => {
        if (
            !sendEmailData.habilidadName.trim() ||
            !sendEmailData.habilidadDescription.trim() ||
            !sendEmailData.destination.trim() ||
            !sendEmailData.subject.trim() ||
            !sendEmailData.body.trim()) {
            setConfirmModalType('❌');
            setConfirmationModalText('Campos vacíos');
            setModalMessage('Por favor, completa todos los campos obligatorios antes de guardar.');
            setPreviousActiveSkill(activeSkill);
            setActiveSkill(null);
            setShowConfirmationModal(true);
            return;
        }
        try {
            setIsLoading(true);
            const properties = {
                destination: sendEmailData.destination,
                subject: sendEmailData.subject,
                body: sendEmailData.body
            }
            const response = await axios.post(`${config.backendURL}/createSkill`, {
                business_id: gojimx_businessId,
                skill_name: sendEmailData.habilidadName,
                skill_description: sendEmailData.habilidadDescription,
                skill_type: activeSkill,
                skill_properties: properties
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${gojimx_token}`
                }
            });

            // Handle successful response
            setConfirmModalType('✅');
            setConfirmationModalText('Habilidad creada con éxito');
            setModalMessage('La habilidad ha sido creada y guardada correctamente.');
            setShowConfirmationModal(true);
            setPreviousActiveSkill(null);
            setSendEmailData({
                habilidadName: '',
                habilidadDescription: '',
                destination: '{{destination}}',
                subject: '{{subject}}',
                body: '{{body}}',
            });
            setRefresh(!refresh);
            handleCloseSkillModal();
        } catch (error: any) {
            // Handle error response
            setConfirmModalType('❌');
            setConfirmationModalText('Error');
            if (error.response.status === 400) {
                setModalMessage('Ya existe una habilidad con este nombre.');

            } else {
                setModalMessage('Hubo un error al crear la habilidad. Por favor, inténtelo de nuevo.');

            }
            setPreviousActiveSkill(activeSkill);
            setActiveSkill(null);
            setShowConfirmationModal(true);
        } finally {
            setIsLoading(false);
            fetchBusiness();
        }
    };

    const handleSaveCalendar = async () => {
        if (
            !calendarData.date.trim() ||
            !calendarData.time.trim() ||
            !calendarData.fullName.trim() ||
            !calendarData.reason.trim() ||
            !calendarData.habilidadName.trim() ||
            !calendarData.habilidadDescription.trim()
        ) {
            setConfirmModalType('❌');
            setConfirmationModalText('Campos vacíos');
            setModalMessage('Por favor, completa todos los campos obligatorios antes de guardar.');
            setPreviousActiveSkill(activeSkill);
            setActiveSkill(null);
            setShowConfirmationModal(true);
            return;
        }
        try {
            setIsLoading(true);/*
            const properties = {
                tituloEvento: calendarData.tituloEvento,
                fechaInicio: calendarData.fechaInicio,
                horaInicio: calendarData.horaInicio,
                fechaFin: calendarData.fechaFin,
                horaFin: calendarData.horaFin,
                eventoDescription: calendarData.eventoDescription,
                participantes: calendarData.participantes,
                ubicacion: calendarData.ubicacion,
            }*/
            const properties = {
                date: calendarData.date,
                time: calendarData.time,
                fullName: calendarData.fullName,
                reason: calendarData.reason,
            }
            //console.log(properties)
            const response = await axios.post(`${config.backendURL}/createSkill`, {
                business_id: gojimx_businessId,
                skill_name: calendarData.habilidadName,
                skill_description: calendarData.habilidadDescription,
                skill_type: activeSkill,
                skill_properties: properties
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${gojimx_token}`
                }
            });

            // Handle successful response
            setConfirmModalType('✅');
            setConfirmationModalText('Habilidad creada con éxito');
            setModalMessage('La habilidad ha sido creada y guardada correctamente.');
            setShowConfirmationModal(true);
            setPreviousActiveSkill(null);

            /*setCalendarData({
                habilidadName: '',
                habilidadDescription: '',
                tituloEvento: '{{titulo}}',
                fechaInicio: '{{fecha}}',
                horaInicio: '{{hora}}',
                fechaFin: '{{fecha}}',
                horaFin: '{{hora}}',
                eventoDescription: '{{descripcion}}',
                participantes: '{{participantes}}',
                ubicacion:'{{ubicacion}}',
            });*/
            setCalendarData({
                habilidadName: '',
                habilidadDescription: '',
                date: '{{Fecha}}',
                time: '{{Hora}}',
                fullName: '{{Nombre}}',
                reason: '{{Motivo}}',
            });
            setRefresh(!refresh);
            handleCloseSkillModal();
        } catch (error: any) {
            // Handle error response
            setConfirmModalType('❌');
            setConfirmationModalText('Error');
            if (error.response.status === 400) {
                setModalMessage('Ya existe una habilidad con este nombre.');

            } else {
                setModalMessage('Hubo un error al crear la habilidad. Por favor, inténtelo de nuevo.');

            }
            setPreviousActiveSkill(activeSkill);
            setActiveSkill(null);
            setShowConfirmationModal(true);
        } finally {
            setIsLoading(false);
            fetchBusiness();
        }
    };

    const handleCloseConfirmationModal = () => {
        if (previousActiveSkill) {
            setActiveSkill(previousActiveSkill);

        }
        setShowConfirmationModal(false);

    };

    useEffect(() => {
        fetchBusinessStatus();
        async function fetchSkills() {
            try {
                const fetchedSkills = await getSkills(gojimx_businessId ?? '');
                setShowSkills(fetchedSkills)
            } catch {
                console.error('Error al cargar las skills');
                setShowSkills([]);
            }
        }
        fetchSkills();
    }, [refresh])

    const handleCancelDelete = () => {
        if (deleteSkillType === 'send_email') {
            setShowEditSendEmailModal(true);
        } else if (deleteSkillType === 'skill_webhook') {
            setShowEditWebhookModal(true);
        } else if (deleteSkillType === 'calendar') {
            setShowEditCalendarModal(true);
        }
        setShowConfirmModal(false);
        setDeteleSkillType('');
    };

    const handleConfirmDelete = async () => {
        if (deleteSkillType === 'send_email') {
            await handleDeleteSendEmailSkill(selectedSkill);
        } else if (deleteSkillType === 'skill_webhook') {
            await handleDeleteWebhookSkill(selectedSkill);
        } else if (deleteSkillType === 'calendar') {
            await handleDeleteCalendarSkill(selectedSkill);
        }
        setShowConfirmModal(false);
    };





    return (
        <div>
            <div className='row px-3 animate__animated animate__fadeIn mb-3'>
                <h5 className='fw-bold text-dark my-0'>Mis Habilidades</h5>
                <div className='row pe-5'>
                    <p className='text-dark mt-2 mb-2 pe-5'>
                        Las habilidades permiten a los asistentes de IA realizar tareas automáticas como enviar correos, gestionar calendarios y crear documentos. Configúralas y asígnalas a los asistentes para ampliar sus capacidades, con un límite de 10 habilidades por asistente.
                    </p>
                </div>
                <div className="pe-5 ps-0 my-3">
                    <div className="border ms-2"></div>
                </div>

                <div className="mt-2 row">
                    {showSkills.filter(skill => skill.skill_type === "send_email").map((skill, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-sm-11 justify-content-center mb-3" onClick={() => handleEditSkill(skill)}>
                            <div className='btn bg-light p-3 mb-1 custom-height-categoria brightness-hover w-90'>
                                <img src={GmailIcon} className='habilidad-icon mb-1' alt="Gmail" />
                                <p className='mb-3'>{skill.skill_name}</p>
                            </div>
                        </div>
                    ))}
                    {showSkills.filter(skill => skill.skill_type === "skill_webhook").map((skill, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-sm-11 justify-content-center mb-3" onClick={() => handleEditSkill(skill)}>
                            <div className='btn bg-light p-3 mb-1 custom-height-categoria brightness-hover w-90'>
                                <img src={WebhookIcon} className='habilidad-icon mb-1' alt="Webhook" />
                                <p className='mb-3'>{skill.skill_name}</p>
                            </div>
                        </div>
                    ))}

                    {showSkills.filter(skill => skill.skill_type === "calendar").map((skill, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-sm-11 justify-content-center mb-3" onClick={() => handleEditSkill(skill)}>
                            <div className='btn bg-light p-3 mb-1 custom-height-categoria brightness-hover w-90'>
                                <img src={CalendarIcon} className='habilidad-icon mb-1' alt="Calendar" />
                                <p className='mb-3'>{skill.skill_name}</p>
                            </div>
                        </div>
                    ))}

                    <div className="col-lg-4 col-md-6 col-sm-11 justify-content-center brightness-hover mb-3">
                        <div className='btn bg-light p-3 mb-1 custom-height-categoria w-90' onClick={handleOpenModal}>
                            <h1><i className="bi bi-plus-lg"></i></h1>
                            <span>Agregar nueva</span>
                        </div>
                    </div>


                </div>
            </div>

            {/* MODAL ADD SKILL */}
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title className='ps-3'>
                        <h4 className='fw-bold'>Añadir Habilidad</h4>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='px-3'>
                        <p className='fs-6 fw-normal'>Selecciona el tipo de habilidad que deseas añadir.</p>
                    </div>

                    <div className='px-3'>
                        <div className='row'>
                            <div className='col-4'>
                                <div className='w-100 p-3' onClick={() => handleSelectSkill('send_email')}>
                                    <div className='gray-skill-option brightness-hover p-3 text-center mb-1'>
                                        <img src={GmailIcon} className='w-40' alt="Gmail" />
                                    </div>
                                    <div className='text-center'>
                                        Gmail
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='w-100 p-3' onClick={() => handleSelectSkill('skill_webhook')}>
                                    <div className='gray-skill-option brightness-hover p-3 text-center mb-1'>
                                        <img src={WebhookIcon} className='w-40' alt="Webhook" />
                                    </div>
                                    <div className='text-center'>
                                        WebHook
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='w-100 p-3' onClick={() => handleSelectSkill('calendar')}>
                                    <div className='gray-skill-option brightness-hover p-3 text-center mb-1'>
                                        <img src={CalendarIcon} className='w-40' alt="Calendar" />
                                    </div>
                                    <div className='text-center'>
                                        Renata Calendar
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer style={{ borderTop: 'none' }}>
                    <div className='text-center w-100'>
                        <Button variant="secondary-outline text-secondary">
                            Cargar más
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* MODAL GMAIL SKILL */}
            <Modal show={activeSkill === 'send_email'} onHide={handleCloseSkillModal} centered size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title className='ps-3'>
                        <h4 className='fw-bold'>Añadir Habilidad: Gmail</h4>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='px-3 mb-2'>
                        <SendEmailForm
                            data={sendEmailData}
                            onChange={handleSendEmailChange}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="light" onClick={handleCloseSkillModal}>
                        Cancelar
                    </Button>

                    <Button variant="dark px-3 w-25" onClick={handleSaveSendEmail}>
                        {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar'}

                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL WEBHOOK SKILL (o cualquier otra habilidad que quieras agregar en el futuro) */}
            <Modal show={activeSkill === 'skill_webhook'} onHide={handleCloseSkillModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='ps-3'>
                        <h4 className='fw-bold'>Añadir Habilidad: WebHook</h4>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {/* Aquí puedes agregar el contenido específico para WebHook */}
                    <div className='px-3 mb-2'>
                        <WebHookForm
                            data={webhookData}
                            onChange={handleWebhookChange}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseSkillModal}>
                        Cancelar
                    </Button>
                    <Button variant="dark" onClick={handleSaveWebhook}>
                        {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar'}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* MODAL WEBHOOK SKILL (o cualquier otra habilidad que quieras agregar en el futuro) */}
            <Modal show={activeSkill === 'calendar'} onHide={handleCloseSkillModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='ps-3'>
                        <h4 className='fw-bold'>Añadir Habilidad: Renata Calendar</h4>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {/* Aquí puedes agregar el contenido específico para WebHook */}
                    <div className='px-3 mb-2'>
                        <CalendarForm
                            data={calendarData}
                            onChange={handleCalendarChange}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseSkillModal}>
                        Cancelar
                    </Button>
                    <Button variant="dark" onClick={handleSaveCalendar}>
                        {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmationModal
                show={showConfirmationModal}
                onHide={handleCloseConfirmationModal}
                text={modalMessage}
                type={confirmModalType}
                isLoading={isLoading}
                titleText='Configuración'
            />

            {showEditWebhookModal && selectedSkill && (
                <EditWebhookSkillModal
                    show={showEditWebhookModal}
                    onHide={() => setShowEditWebhookModal(false)}
                    data={selectedSkill}
                    onSave={handleSaveEditedWebhookSkill}
                    isLoading={isLoading}
                    onDelete={() => {
                        setDeteleSkillType('skill_webhook');
                        setShowEditWebhookModal(false);
                        setShowConfirmModal(true);
                    }}
                    isLoadingEditSkill={isLoadingEditSkill}
                />
            )}

            {showEditSendEmailModal && selectedSkill && (
                <EditWSendEmailSkillModal
                    show={showEditSendEmailModal}
                    onHide={() => setShowEditSendEmailModal(false)}
                    data={selectedSkill}
                    onSave={handleSaveEditedSendEmailSkill}
                    isLoading={isLoading}
                    onDelete={handleDeleteSendEmailSkill}
                    handleDelete={() => {
                        setDeteleSkillType('send_email');
                        setShowEditSendEmailModal(false);
                        setShowConfirmModal(true);
                    }}
                    isLoadingEditSkill={isLoadingEditSkill}

                />
            )}
            {showEditCalendarModal && selectedSkill && (
                <EditWCalendarSkillModal
                    show={showEditCalendarModal}
                    onHide={() => setShowEditCalendarModal(false)}
                    data={selectedSkill}
                    onSave={handleSaveEditedCalendarSkill}
                    isLoading={isLoading}
                    onDelete={() => {
                        setDeteleSkillType('calendar');
                        setShowEditCalendarModal(false);
                        setShowConfirmModal(true);
                    }}
                    handleDelete={() => {
                        setDeteleSkillType('calendar');
                        setShowEditCalendarModal(false);
                        setShowConfirmModal(true);
                    }}
                    isLoadingEditSkill={isLoadingEditSkill}
                />
            )}

            <DeleteConfirmationModal
                show={showConfirmModal}
                onHide={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                message="¿Estás seguro de que quieres borrar la habilidad? Se eliminará también de los asistentes"
                isLoading={isLoading}
            />

        </div>
    );
};

export default MySkills;