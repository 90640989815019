import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import loadingGif from '../../img/loading.gif';
import WhatsAppLogo from '../../img/WhatsApp_Logo.png';
import InstagramLogo from '../../img/Instagram_Logo.png';
import LinkedinLogo from '../../img/Linkedin_Logo.png';
import XLogo from '../../img/X_Logo.png';
import MessengerLogo from '../../img/Messenger_Logo.png';
import config from '../../config/config';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import QRCode from 'react-qr-code';

interface ChannelOptionsModalProps {
    show: boolean;
    onHide: () => void;
    isLoading?: boolean;
    fetchBusiness: () => void;
    channel?: Channel;
}

interface Channel {
    channel_name: string;
    active: boolean;
    username: string;
    main_number: boolean,
    baileys_port: string,
    baileys_status: boolean,
}

const ChannelOptionsModal: React.FC<ChannelOptionsModalProps> = ({ show, onHide, isLoading = false, fetchBusiness, channel }) => {

    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [confirmationModalText, setConfirmationModalText] = useState('');
    const [confirmationModalType, setConfirmationModalType] = useState('');
    const [deleteConfirmationModalShow, setDeleteConfirmationModalShow] = useState(false);
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const gojimx_token = localStorage.getItem('gojimx_token');
    const [qrCode, setQrCode] = useState<string>('');
    const [baileys_status, setBaileysStatus] = useState(false);
    const [error, setError] = useState(false);
    const [isDeleting, setIsDeleing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingQR, setLoadingQR] = useState(false)

    useEffect(() => {
        const fetchQrCode = async () => {
            try {
                setLoadingQR(true)
                const response = await axios.get(`${config.apiBaileys}/${channel?.baileys_port}/getQR`, {
                    headers: {
                        Authorization: `Bearer ${gojimx_token}`
                    }
                });
                setQrCode(response.data);
            } catch (error) {
                console.error('Error fetching QR code:', error);
                setError(true);
            } finally {
                setLoadingQR(false)
            }
        }
        if (channel && show) {
            console.log(channel.baileys_status)
            setBaileysStatus(channel.baileys_status)
            if (!channel.baileys_status) {
                const intervalId = setInterval(fetchQrCode, 50000); // Fetch QR code every 50 seconds
                fetchQrCode(); // Initial fetch
                return () => clearInterval(intervalId);
            }
        }
    }, [show]);

    useEffect(() => {
        const fetchBusinessStatus = async () => {
            if (!gojimx_businessId || !gojimx_token) {
                console.error('Missing business_id or gojimx_token');
                return;
            }
            try {
                const response = await axios.get(`${config.backendURL}/get_business/${gojimx_businessId}`, {
                    headers: {
                        Authorization: `Bearer ${gojimx_token}`
                    }
                });
                const { channels } = response.data[0];
                channels.forEach((newChannel: Channel) => {
                    if (newChannel.username == channel?.username) {
                        setBaileysStatus(newChannel.baileys_status);
                        fetchBusiness();
                    }
                });
            } catch (error) {
                console.error('Error fetching business status:', error);
            } finally {
                setLoading(false);
                console.log(loading);

            }
        };

        const intervalId = setInterval(fetchBusinessStatus, 5000);
        return () => clearInterval(intervalId);
    }, [gojimx_businessId, channel]);

    const closeModal = () => {
        // setError(false);
        // setBaileysStatus(false);
        // setQrCode('');
        onHide();
    }

    const closeModalForDeleteConfirmation = () => {
        onHide();
    }

    const handleDeleteChannel = async () => {
        try {
            const response = await axios.post(`${config.apiBaileys}/deleteRenataBaileysFromChannel`, {
                business_id: gojimx_businessId,
                username: channel?.username
            }, {
                headers: {
                    Authorization: `Bearer ${gojimx_token}`
                }
            });
            setDeleteConfirmationModalShow(false);
            fetchBusiness();
            closeModal();
            setConfirmationModalText('Canal de WhatsApp eliminado correctamente, la página se recargará automáticamente.');
            setConfirmationModalType('✅');
            setConfirmationModalShow(true);
            setIsDeleing(false);
            setTimeout(() => {
                window.location.reload();
            }, 2500);
        } catch (error) {
            setDeleteConfirmationModalShow(false);
            fetchBusiness();
            closeModal();
            setConfirmationModalText('No se pudo eliminar el canal de WhatsApp, inténtelo más tarde.');
            setConfirmationModalType('❌');
            setConfirmationModalShow(true);
            setIsDeleing(false);
            console.error('Error deleting channel:', error);
        }
    }

    const handleDeleteClick = () => {
        closeModalForDeleteConfirmation();
        setDeleteConfirmationModalShow(true);
    }

    const handleConfirmDelete = () => {
        setIsDeleing(true);
        handleDeleteChannel();
    }

    return (
        <>
            <Modal show={show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <div className='col'>
                        <Modal.Title>WhatsApp</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='animate__animated animate__fadeIn'>
                            {error ?
                                <>
                                    <p>Ocurrió un error al recuperar el código QR, inténtelo más tarde o póngase en contacto con Goji.</p>
                                </>
                                :
                                loading ? 
                                <div className='my-5' style={{ display: 'flex', justifyContent: 'center' }}>
                                <h1 style={{ fontWeight: 'bold' }}><img className='loading' src={loadingGif} alt="Cargando..." /></h1>
                            </div>
                                 : 
                                 (
                                !baileys_status && qrCode.length > 0 ?
                                    <div>
                                        <div>
                                            <h1 style={{ fontWeight: 'bold' }}>Te damos la bienvenida a Renata! 🎉</h1>
                                            {loadingQR ?
                                                <div className='my-5' style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <h1 style={{ fontWeight: 'bold' }}><img className='loading' src={loadingGif} alt="Cargando..." /></h1>
                                                </div>
                                                :
                                                <div className='my-5' style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div className='bg-secondary p-3 rounded'>
                                                        <div className='bg-light p-3 rounded'>
                                                            <QRCode value={qrCode} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className='my-5' style={{ textAlign: 'center' }}>
                                                <h4>Para comenzar a automatizar tu WhatsApp, por<br></br>favor escanea el QR</h4>
                                            </div>
                                            <div>
                                                <h5><span className='me-3 ms-4'>1. </span>Abre Whatsapp en tu teléfono</h5>
                                                <h5><span className='me-3 ms-4'>2. </span>En la configuración, entra a "Dispositivos Enlazados"</h5>
                                                <h5><span className='me-3 ms-4'>3. </span>Con la cámara del teléfono, captura el código en pantalla</h5>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        <p>Su WhatsApp se encuentra conectado correctamente</p>
                                    </>
                                 )
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {!channel?.main_number && !error &&
                        <Button variant="danger" onClick={handleDeleteClick}>
                            Eliminar canal
                        </Button>
                    }
                    <Button variant="dark" onClick={closeModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={deleteConfirmationModalShow} onHide={() => setDeleteConfirmationModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas eliminar este canal?
                </Modal.Body>
                <Modal.Footer>
                    {!isDeleting ?
                        <>
                            <Button variant="dark" onClick={() => setDeleteConfirmationModalShow(false)}>
                                Cancelar
                            </Button>
                            <Button variant="danger" onClick={handleConfirmDelete}>
                                Eliminar
                            </Button>
                        </>
                        :
                        <div className='ms-auto me-3'>
                            <h1 style={{ fontWeight: 'bold' }}><img className='loading' src={loadingGif} alt="Cargando..." /></h1>
                        </div>
                    }
                </Modal.Footer>
            </Modal>
            <ConfirmationModal
                show={confirmationModalShow}
                onHide={() => setConfirmationModalShow(false)}
                text={confirmationModalText}
                type={confirmationModalType}
                titleText="Resultado de la Operación"
            />
        </>
    );
};

export default ChannelOptionsModal;