import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

interface SendEmailFormProps {
  data: {
    habilidadName: string;
    habilidadDescription: string;
    destination: string;
    subject: string;
    body: string;
  };
  onChange: (data: SendEmailFormProps['data']) => void;
}

const SendEmailForm: React.FC<SendEmailFormProps> = ({ data, onChange }) => {
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    onChange(formData); // Llamar onChange cada vez que formData cambie
  }, [formData]);

  useEffect(() => {
    setFormData(data); // Sincroniza formData con los datos recibidos cuando cambian
  }, [data]);

  return (
    <Form className="p-3">
      <Form.Group controlId="habilidadName" className="mb-4">
        <Form.Label className="fw-bold">Identificador de la habilidad</Form.Label>
        <Form.Control
          type="text"
          value={formData.habilidadName}
          onChange={(e) => setFormData({ ...formData, habilidadName: e.target.value })}
          placeholder="Introduce el nombre de la habilidad"
        />
      </Form.Group>

      <Form.Group controlId="habilidadDescription" className="mb-4">
        <Form.Label className="fw-bold">Descripción de la habilidad</Form.Label>
        <Form.Control
          type="text"
          value={formData.habilidadDescription}
          onChange={(e) => setFormData({ ...formData, habilidadDescription: e.target.value })}
          placeholder="Describe la habilidad"
        />
      </Form.Group>

      <Form.Group controlId="destination" className="mb-4">
        <Form.Label className="fw-bold">Destinatario</Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{destination}}'}</code> para dejar que renata autocomplete
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.destination}
          onChange={(e) => setFormData({ ...formData, destination: e.target.value })}
          placeholder="{{destination}}"
        />
      </Form.Group>

      <Form.Group controlId="subject" className="mb-4">
        <Form.Label className="fw-bold">Asunto</Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{subject}}'}</code> para dejar que renata autocomplete           
        </Form.Label>
        <Form.Control
          type="text"
          value={formData.subject}
          onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
          placeholder="{{subject}}"
        />
      </Form.Group>

      <Form.Group controlId="body" className="mb-4">
        <Form.Label className="fw-bold">Cuerpo</Form.Label>
        <Form.Label className="mt-0 mb-2">
            Utiliza la variable <code>{'{{body}}'}</code> para dejar que renata autocomplete           
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={formData.body}
          onChange={(e) => setFormData({ ...formData, body: e.target.value })}
          placeholder="{{body}}"
         
        />
      </Form.Group>

    </Form>
  );
};

export default SendEmailForm;