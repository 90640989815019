import React, { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import loadingGif from '../../img/loading.gif';
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal'; // Importa el componente de confirmación


interface Skill {
    skill_type: string;
    skill_name: string;
    skill_description: string;
    skill_id: string;
    skill_properties: {
        [key: string]: any;
    };
}

interface EditSendEmailSkillModalProps {
    show: boolean;
    onHide: () => void;
    data: Skill;
    onSave: (updatedData: Skill) => void;
    isLoading: boolean;
    onDelete: (updatedData: Skill) => void;
    handleDelete: () => void;
    isLoadingEditSkill: boolean;
}

const EditSendEmailSkillModal: React.FC<EditSendEmailSkillModalProps> = ({ show, onHide, data, onSave, isLoading, onDelete, handleDelete, isLoadingEditSkill }) => {
    const [formData, setFormData] = useState<Skill>(data);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        setFormData(data);
    }, [data]);

    const handleChange = (field: keyof Skill, value: any) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const handleSkillPropertyChange = (property: keyof Skill['skill_properties'], value: string) => {
        setFormData({
            ...formData,
            skill_properties: {
                ...formData.skill_properties,
                [property]: value,
            },
        });
    };

    const handleSave = async () => {
        await onSave(formData);
        onHide();
    };

    // const handleDelete= async () => {
    //     setShowConfirmModal(true);
    //     //await onDelete(formData);
    //     //onHide();
    // }
    const handleConfirmDelete = async () => {
        setShowConfirmModal(false);
        await onDelete(formData);
        onHide();
        
    };

    const handleCancelDelete = () => {
        setShowConfirmModal(false); // Solo cierra el modal de confirmación
    };

    return (
        <>
        <Modal  show={show} onHide={onHide} size='lg' centered>
            <Modal.Header closeButton>
                <Modal.Title className='ps-3'>
                    <h4 className='fw-bold'>Editar Habilidad: Correo</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="p-3">
                    <Form.Group controlId="habilidadName" className="mb-4">
                        <Form.Label className="fw-bold">Identificador de la habilidad</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.skill_name}
                            onChange={(e) => handleChange('skill_name', e.target.value)}
                            placeholder="Introduce el nombre de la habilidad"
                        />
                    </Form.Group>

                    <Form.Group controlId="habilidadDescription" className="mb-4">
                        <Form.Label className="fw-bold">Descripción de la habilidad</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.skill_description}
                            onChange={(e) => handleChange('skill_description', e.target.value)}
                            placeholder="Describe la habilidad"
                        />
                    </Form.Group>

                    <Form.Group controlId="destination" className="mb-4">
                        <Form.Label className="fw-bold">Destinatario</Form.Label>
                        <Form.Label className="mt-0 mb-2">
                            Utiliza la variable <code>{'{{destination}}'}</code> para dejar que renata autocomplete
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.skill_properties.destination}
                            onChange={(e) => handleSkillPropertyChange('destination', e.target.value)}
                            placeholder="{{destination}}"
                        />
                    </Form.Group>

                    <Form.Group controlId="subject" className="mb-4">
                        <Form.Label className="fw-bold">Asunto</Form.Label>
                        <Form.Label className="mt-0 mb-2">
                            Utiliza la variable <code>{'{{subject}}'}</code> para dejar que renata autocomplete
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.skill_properties.subject}
                            onChange={(e) => handleSkillPropertyChange('subject', e.target.value)}
                            placeholder="{{subject}}"
                        />
                    </Form.Group>

                    <Form.Group controlId="body" className="mb-4">
                        <Form.Label className="fw-bold">Cuerpo</Form.Label>
                        <Form.Label className="mt-0 mb-2">
                            Utiliza la variable <code>{'{{body}}'}</code> para dejar que renata autocomplete
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={formData.skill_properties.body}
                            onChange={(e) => handleSkillPropertyChange('body', e.target.value)}
                            placeholder="{{body}}"
                        />
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>
                    {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Borrar'}
                </Button>
                <Button variant="light" onClick={onHide}>
                    Cancelar
                </Button>
                <Button variant="dark" onClick={handleSave}>
                    {isLoadingEditSkill ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar'}
                </Button>
            </Modal.Footer>
        </Modal>

       
            </>
    );
};

export default EditSendEmailSkillModal;